import React from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap
// import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, Container } from "reactstrap";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import jobLogo from '../../assets/images/jrh_logo.png';

// import Css
import '../../assets/css/Layout/Header.css';


//i18n
import { withTranslation } from "react-i18next";
import { Configure } from "pages/configure";

const Header = props => {

  return (
    <React.Fragment>
      <header id="page-topbar" className="Header">
        <div className="navbar-header px-0">
          <div className="d-flex">
            <div className="navbar-brand-box ps-0">
              <Link to={`${Configure.appUrl}`} className="logo logo-light" >
                <span className="job-logo">
                  <img src={jobLogo} alt="JRH logo" className="job-logo" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

          </div>

          <div className="d-flex">

            <ProfileMenu />
            {/* <NotificationDropdown /> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));