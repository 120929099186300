import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card } from "reactstrap";

// Import Components
import WelcomeComp from "./WelcomeComp";
import { Link, useNavigate } from "react-router-dom";
import { CardBody } from "reactstrap";


//i18n
import { withTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import { Configure, getDecryptedData } from "pages/configure";

import axios from "axios"
import Loader from "pages/Loader";

import profileImg from "../../assets/images/jrh_user.png"
import swal from "sweetalert";

const Dashboard = props => {
  const [patientCount, setPatientCount] = useState(0)
  const [caseCount, setCaseCount] = useState(0)
  const [surgeonCount, setSurgeonCount] = useState(0)
  const [loading, setLoading] = useState(0)

  const [searchStr, setSearchStr] = useState("")
  const [caseList, setCaseList] = useState([])
  const [searchCase, setSearchCase] = useState([])

  const navigate = useNavigate()


  useEffect(() => {
    getAllCounts()
    getAllCases()
  }, [])

  async function getAllCounts() {
    try {
      setLoading(true)
      const response = await axios.get(`${Configure.apiUrl}/counts`, {
        headers: {
          Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
          'Content-Type': 'application/json'
        }
      })

      setPatientCount(response.data.data.total_patients)
      setCaseCount(response.data.data.all_cases)
      setSurgeonCount(response.data.data.total_surgeons)
      setLoading(false)
    } catch (error) {
      console.log("Error Fetching data: ", error)
    }
  }

  //meta title
  document.title = "Dashboard | John Radcliffe Hospital ";

  const reports = [
    { title: "Total Surgeons", iconClass: "fas fa-user-md ", description: surgeonCount, link: `${Configure.appUrl}surgeons` },
    { title: "Total Patients", iconClass: "fas fa-hospital-user ", description: patientCount, link: `${Configure.appUrl}patients` },
    { title: "Total Cases", iconClass: "fas fa-address-card ", description: caseCount, link: `${Configure.appUrl}cases` },
  ];

  async function getAllCases() {
    try {

      const response = await axios.get(`${Configure.apiUrl}/patients/allcases`, {
        headers: {
          Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
          'Content-Type': 'application/json'
        }
      })
      const finalData = response.data.data.map((temp) => {
        return ({
          ...temp,
          name: getDecryptedData(temp.name),
          nhs_number: temp.nhs_number,
          hospital_number: getDecryptedData(temp.hospital_number),
          status: ((temp.mdt_decision && temp.iotn && temp.ioftn) ? "Completed" : "Pending")
        })
      })
      // console.log(finalData)

      setCaseList(finalData)
    } catch (error) {
      console.log("Error Fetching data: ", error)
    }
  }

  const onSearchCase = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      const foundCases = caseList.filter((item) => (item.nhs_number == searchStr) || (item.hospital_number == searchStr))
      setSearchCase(foundCases)
      if (foundCases.length === 0)
        swal("", "No Case Found !!", "warning")
      // setSearchStr("")
    }, 1000)
  }

  return (
    <React.Fragment>
      {
        loading && <Loader />
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row className="px-0">
            <Col xl="4" className="ps-md-0" >
              <WelcomeComp />
              {/* <MonthlyEarning /> */}
            </Col>
            <Col xl="8" className="pe-md-0" >

              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Link to={report.link}>
                          <div className="d-flex">

                            <div className="avatar-sm rounded-circle bg-blue align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-blue">
                                <i
                                  className={
                                    report.iconClass + " font-size-20"
                                  }
                                ></i>
                              </span>
                            </div>

                            <div className="flex-grow-1 ms-3">
                              <p className="text-muted fw-medium mb-2 ">
                                {report.title}
                              </p>
                              <h4 className="mb-0 text-dark">
                                {report.description < 10 ? '0' + report.description : report.description}
                              </h4>
                            </div>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <h5 className="text-blue fw-bold mb-3">Search Cases</h5>
                  <div className="d-flex align-items-center">
                    <div style={{ flex: 1 }} className="me-4 position-relative">
                      <input type="search" className="form-control ps-5"
                        value={searchStr}
                        onChange={(e) => setSearchStr(e.target.value)}
                        placeholder="Search Case using NHS Id" />
                      <i className="fas fa-search"
                        style={{
                          position: "absolute",
                          top: 13,
                          left: 19
                        }}
                      ></i>
                    </div>
                    <button onClick={onSearchCase} className="btn btn-primary bg-blue">
                      <i className="fas fa-search me-2"></i>
                      Search
                    </button>
                  </div>

                  {
                    searchCase.length > 0 ?
                      <div className="mt-4">
                        {
                          searchCase?.map((list, i) => {
                            return (
                              <div key={i} className="px-3 py-2 mt-3 bg-light d-flex align-items-center" style={{ borderRadius: 50 }}>
                                <img src={profileImg} alt="" width={50} style={{ borderRadius: 50 }} />
                                <div className="ms-4">
                                  <p className="mb-1 fs-5">{list.name}</p>
                                  <span className={`fw-bold text-${(list.status == "Completed" ? "success" : "danger")}`}>{list.status}</span>
                                </div>

                                <div className="ms-4">
                                  <p className="mb-1 fs-5">NHS Number</p>
                                  <span className="fw-bold">{list.nhs_number}</span>
                                </div>

                                <div style={{ flex: 1 }} className="text-end ">
                                  <button
                                    onClick={() => {
                                      navigate(`${Configure.appUrl}verify-case`, {
                                        state: {
                                          answers: list,
                                        }
                                      })
                                    }}
                                    className="btn btn-light">
                                    View More..
                                  </button>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      :
                      <div className="mt-4">
                        <p>Search Cases will be appear here..</p>
                      </div>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};


export default withTranslation()(Dashboard);
