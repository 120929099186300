import PropTypes, { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, Input, Label, Form, Modal, ModalBody } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// import images
import profile from "../../assets/images/profile-img.png";
import { Configure } from "pages/configure";
import axios from "axios";
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";

const ResetPassword = props => {
    const [password, setPassword] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [loading, setLoading] = useState(false)
    const [show_pwd, setShowPwd] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (!secureLocalStorage.getItem('temp_data')) {
            navigate(`${Configure.appUrl}login`)
        }
    })

    //meta title
    document.title = "Forget Password | John Radcliffe Hospital";


    const handleResetPassword = async () => {
        if (password === confirmPwd) {
            try {
                setLoading(true)
                const optResponse = secureLocalStorage.getItem('temp_data')

                const formData = {
                    user_id: optResponse?.user_id,
                    password
                }

                const response = await axios.post(`${Configure.apiUrl}/change_password`, formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                setLoading(false)

                if (response.data.Message == "Password reset succesfully...") {
                    swal("", "Password Changed Successfully", "success")
                    navigate(`${Configure.appUrl}login`)
                }

            } catch (error) {
                swal("", "Something Went Wrong!", "error")
                console.log('Error: ', error);
                setLoading(false)
                // console.log(error)
            }
        } else {
            swal("", "Confirm Password is Wrong!!", "error")
        }
    }

    return (
        <React.Fragment>
            <div className="account-pages login-page  login-bg pt-sm-5">
                <Container>
                    <Row className="justify-content-center align-items-center mt-5">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={8}>
                                            <div className="text-blue p-4">
                                                <h5 className="text-blue fw-bold">Reset Password</h5>
                                                <p style={{ fontWeight: 500 }}>Email Verified Successfully! <br /> Now you can change your password</p>
                                            </div>
                                        </Col>
                                        <Col className="col-4 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">

                                    <div className="p-2 mt-4">

                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleResetPassword()
                                            }}
                                        >
                                            <div className="mb-4">
                                                <Label className="form-label">New Password</Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder="Enter New Password"
                                                    type={show_pwd ? "text" : "password"}
                                                    required
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>

                                            <div className="">
                                                <Label className="form-label">Confirm Password</Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder="Enter Confirm Password"
                                                    type={show_pwd ? "text" : "password"}
                                                    required
                                                    onChange={(e) => setConfirmPwd(e.target.value)}
                                                />
                                            </div>

                                            <div className="form-check mt-2 mb-4">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                    onChange={(e) => setShowPwd(e.target.checked)}

                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customControlInline"
                                                >
                                                    Show Password
                                                </label>
                                            </div>

                                            <button
                                                className="btn btn-primary bg-blue w-100 "
                                                type="submit"
                                            >
                                                {
                                                    loading &&
                                                    <i className="bx bx-hourglass bx-spin align-middle me-2"></i>
                                                }
                                                {loading ? "Resetting Password.." : "Reset"}
                                            </button>

                                        </Form>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <p>
                                            © 2024 FaceFacts. Crafted with by Zithas Technologies
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

ResetPassword.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ResetPassword);
