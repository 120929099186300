import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import AllSurgeons from "pages/Surgeons/AllSurgeons"
import AllPatients from "pages/Patients/AllPatients"
import AllCases from "pages/Cases/AllCases"
import VerifyCase from "pages/Cases/VerifyCase"
import PatientCases from "pages/Cases/PatientCases"
import AddCase from "pages/Cases/AddCase"
import { Configure } from "pages/configure"
import ResetPassword from "pages/Authentication/ResetPassword"

const authProtectedRoutes = [
  { path: `${Configure.appUrl}dashboard`, component: <Dashboard /> },
  { path: `${Configure.appUrl}surgeons`, component: <AllSurgeons /> },
  { path: `${Configure.appUrl}patients`, component: <AllPatients /> },
  { path: `${Configure.appUrl}patients/cases`, component: <PatientCases /> },
  { path: `${Configure.appUrl}cases`, component: <AllCases /> },
  { path: `${Configure.appUrl}add-case`, component: <AddCase /> },
  { path: `${Configure.appUrl}verify-case`, component: <VerifyCase /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: `${Configure.appUrl}`,
    exact: true,
    component: < Navigate to={`${Configure.appUrl}dashboard`} />,
  },
]

const publicRoutes = [
  { path: `${Configure.appUrl}logout`, component: <Logout /> },
  { path: `${Configure.appUrl}login`, component: <Login /> },
  { path: `${Configure.appUrl}forgot-password`, component: <ForgetPwd /> },
  { path: `${Configure.appUrl}reset-password`, component: <ResetPassword /> },
  { path: `${Configure.appUrl}register`, component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
