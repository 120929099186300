import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/jrh_user.png";
import secureLocalStorage from "react-secure-storage";
import AdminProfile from "pages/Dashboard/AdminProfile";
import { Configure } from "pages/configure";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [profileModal, setProfileModal] = useState(false);

  const navigate = useNavigate()

  const handleLogout = () => {
    secureLocalStorage.removeItem("admin")
    secureLocalStorage.removeItem("adminId")
    secureLocalStorage.removeItem("token")
    navigate(`${Configure.appUrl}login`)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1 text-blue fw-bold">{secureLocalStorage.getItem("admin")}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-blue" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => setProfileModal(true)} >
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1 text-blue" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          <button onClick={handleLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>

      <AdminProfile modal={profileModal} setModal={setProfileModal} />
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
