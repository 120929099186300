import React from 'react'

const StatusBadge = ({ text1, text2 }) => {
    return (
        <div className="d-flex justify-content-between mb-3 ps-4 pe-5 mt-1">
            <div className="px-2 badge py-1 fw-bold bg-light rounded-pill text-capitalize text-success" style={{ fontSize: 11 }}>
                {text1}
            </div>
            <div className="px-2 badge py-1 fw-bold bg-light rounded-pill text-capitalize text-danger" style={{ fontSize: 11 }}>
                {text2}
            </div>
        </div>
    )
}

export default StatusBadge