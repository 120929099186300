import React, { useMemo, Fragment, useState, useEffect } from "react"

import { Row, Container, CardBody, Card, Modal, ModalBody } from "reactstrap"

import Breadcrumb from "components/Common/Breadcrumb"
import { Link, useLocation, useNavigate } from "react-router-dom"

import swal from 'sweetalert';
import axios from "axios"
import Loader from "pages/Loader"
import { Configure, getDecryptedData, getEncryptedData } from "pages/configure"
import secureLocalStorage from "react-secure-storage"
import moment from "moment"
import StatusBadge from "./StatusBadge";

const VerifyCase = () => {

    const [radioQuestion1, setRadioQuestion1] = useState(0);
    const [radioQuestion2, setRadioQuestion2] = useState(0);
    const [radioQuestion3, setRadioQuestion3] = useState(0);
    const [radioQuestion4, setRadioQuestion4] = useState(0);
    const [radioQuestion5, setRadioQuestion5] = useState(0);
    const [radioQuestion6, setRadioQuestion6] = useState(0);
    const [radioQuestion7, setRadioQuestion7] = useState(0);
    const [radioQuestion8, setRadioQuestion8] = useState(0);

    const [step3_quest1, setStep3Ques1] = useState(0);
    const [step3_quest2, setStep3Ques2] = useState(0);
    const [step3_quest3, setStep3Ques3] = useState(0);
    const [step3_quest4, setStep3Ques4] = useState(0);
    const [step3_quest5, setStep3Ques5] = useState(0);
    const [step3_quest6, setStep3Ques6] = useState(0);
    const [step3_quest7, setStep3Ques7] = useState(0);
    const [step3_quest8, setStep3Ques8] = useState(0);
    const [step3_quest9, setStep3Ques9] = useState(0);
    const [step3_quest10, setStep3Ques10] = useState(0);

    const [step4_quest1, setStep4Ques1] = useState(0);
    const [step4_quest2, setStep4Ques2] = useState(0);
    const [step4_quest3, setStep4Ques3] = useState(0);
    const [step4_quest4, setStep4Ques4] = useState(0);

    const [step5_quest1, setStep5Ques1] = useState(0);
    const [step5_quest2, setStep5Ques2] = useState(0);

    const [yourBite, setYourBite] = useState("");
    const [yourAppearance, setYourAppearance] = useState("");
    const [education_impact, setEducationImpact] = useState("");

    const [caseId, setCaseId] = useState('');
    const [userId, setUserId] = useState('');
    // const [mdt_decision1, setMtdDecision] = useState("")

    // feedback
    const [feedBackModal, setFeedBackModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const [iotn, setIotn] = useState()
    const [ioftn, setIoftn] = useState()
    const [mdt_decision, setMtdDecision] = useState()

    const [AI_iotn, setAI_iotn] = useState({})
    const [AI_ioftn, setAI_ioftn] = useState({})
    const [AI_mdt_decision, setAI_mdt_decision] = useState({})

    const [like1, setLike1] = useState(Number(0))
    const [like2, setLike2] = useState(Number(1))
    const [like3, setLike3] = useState(Number(2))

    const navigate = useNavigate()
    const location = useLocation()
    const caseData = location?.state?.answers

    const first_2_options = [
        {
            label: "No Need for Treatment",
            value: 1
        },
        {
            label: "Mild Need for Treatment",
            value: 2
        },
        {
            label: "Moderate Need for Treatment",
            value: 3
        },
        {
            label: "Great Need for Treatment",
            value: 4
        },
        {
            label: "Very Great Need for Treatment",
            value: 5
        }
    ]

    const third_options = [
        {
            label: "No Treatment",
            value: 1,
        },
        {
            label: "Ortho Only",
            value: 2,
        },
        {
            label: "Orthognathic",
            value: 3,
        }
    ]

    document.title = "Case | John Radcliffe Hospital"

    // getting all answers
    useEffect(() => {
        function getAnswers() {
            try {
                setRadioQuestion1(getDecryptedData(caseData?.feeling))
                setRadioQuestion2(getDecryptedData(caseData?.worrying))
                setRadioQuestion3(getDecryptedData(caseData?.conditions))
                setRadioQuestion4(getDecryptedData(caseData?.pleasure))

                setRadioQuestion5(getDecryptedData(caseData?.appearance))
                setRadioQuestion6(getDecryptedData(caseData?.behaviours))
                setRadioQuestion7(getDecryptedData(caseData?.activities))
                setRadioQuestion8(getDecryptedData(caseData?.weight))

                if (getDecryptedData(caseData?.teeth_position) && getDecryptedData(caseData?.teeth_position) != "undefined")
                    setStep3Ques1(getDecryptedData(caseData?.teeth_position))

                if (getDecryptedData(caseData?.teeth_position) && getDecryptedData(caseData?.teeth_position) != "undefined")
                    setStep3Ques2(getDecryptedData(caseData?.teeth_bite))
                if (getDecryptedData(caseData?.face) && getDecryptedData(caseData?.face) != "undefined")
                    setStep3Ques3(getDecryptedData(caseData?.face))
                if (getDecryptedData(caseData?.cheeks) && getDecryptedData(caseData?.cheeks) != "undefined")
                    setStep3Ques4(getDecryptedData(caseData?.cheeks))
                if (getDecryptedData(caseData?.lower_jaw) && getDecryptedData(caseData?.lower_jaw) != "undefined")
                    setStep3Ques5(getDecryptedData(caseData?.lower_jaw))
                if (getDecryptedData(caseData?.chin) && getDecryptedData(caseData?.chin) != "undefined")
                    setStep3Ques6(getDecryptedData(caseData?.chin))
                if (getDecryptedData(caseData?.cheek_bone) && getDecryptedData(caseData?.cheek_bone) != "undefined")
                    setStep3Ques7(getDecryptedData(caseData?.cheek_bone))
                if (getDecryptedData(caseData?.jaw_joint) && getDecryptedData(caseData?.jaw_joint) != "undefined")
                    setStep3Ques8(getDecryptedData(caseData?.jaw_joint))
                if (getDecryptedData(caseData?.night_snore) && getDecryptedData(caseData?.night_snore) != "undefined")
                    setStep3Ques9(getDecryptedData(caseData?.night_snore))
                if (getDecryptedData(caseData?.sleepy_feel) && getDecryptedData(caseData?.sleepy_feel) != "undefined")
                    setStep3Ques10(getDecryptedData(caseData?.sleepy_feel))

                if (getDecryptedData(caseData?.ortho_treatment) && getDecryptedData(caseData?.ortho_treatment) != "undefined")
                    setStep4Ques1(getDecryptedData(caseData?.ortho_treatment))
                if (getDecryptedData(caseData?.numbness) && getDecryptedData(caseData?.numbness) != "undefined")
                    setStep4Ques2(getDecryptedData(caseData?.numbness))
                if (getDecryptedData(caseData?.nasal) && getDecryptedData(caseData?.nasal) != "undefined")
                    setStep4Ques3(getDecryptedData(caseData?.nasal))
                if (getDecryptedData(caseData?.revision_operation) && getDecryptedData(caseData?.revision_operation) != "undefined")
                    setStep4Ques4(getDecryptedData(caseData?.revision_operation))

                if (getDecryptedData(caseData?.treatment) && getDecryptedData(caseData?.treatment) != "undefined")
                    setStep5Ques1(getDecryptedData(caseData?.treatment))
                if (getDecryptedData(caseData?.support) && getDecryptedData(caseData?.support) != "undefined")
                    setStep5Ques2(getDecryptedData(caseData?.support))

                setYourBite(getDecryptedData(caseData?.bite_change))
                setYourAppearance(getDecryptedData(caseData?.appearance_change))
                setEducationImpact(getDecryptedData(caseData?.education_impact))

                setCaseId(caseData?.id)
                setUserId(caseData?.user_id)

                // console.log('caseData', caseData)

                if (caseData?.iotn)
                    setIotn(getDecryptedData(caseData?.iotn))
                if (caseData?.ioftn)
                    setIoftn(getDecryptedData(caseData?.ioftn))

                setMtdDecision(getDecryptedData(caseData?.mdt_decision))

            } catch (error) {
                console.log("Error Fetching data: ", error)
            }
        }
        getAnswers()
    }, [])


    // Assessment Operations =============================================

    const updateHistory = async () => {
        try {
            const formData = new FormData()

            setLoading(true)

            formData.append("feeling", getEncryptedData(radioQuestion1));
            formData.append("worrying", getEncryptedData(radioQuestion2));
            formData.append("condition", getEncryptedData(radioQuestion3));
            formData.append("pleasure", getEncryptedData(radioQuestion4));
            formData.append("appearance", getEncryptedData(radioQuestion5));
            formData.append("behaviours", getEncryptedData(radioQuestion6));
            formData.append("activities", getEncryptedData(radioQuestion7));
            formData.append("weight", getEncryptedData(radioQuestion8));
            formData.append("teeth_position", getEncryptedData(step3_quest1));
            formData.append("teeth_bite", getEncryptedData(step3_quest2));
            formData.append("face", getEncryptedData(step3_quest3));
            formData.append("cheeks", getEncryptedData(step3_quest4));
            formData.append("lower_jaw", getEncryptedData(step3_quest5));
            formData.append("chin", getEncryptedData(step3_quest6));
            formData.append("cheeck_bone", getEncryptedData(step3_quest7));
            formData.append("jaw_joint", getEncryptedData(step3_quest8));
            formData.append("night_snore", getEncryptedData(step3_quest9));
            formData.append("sleepy_feel", getEncryptedData(step3_quest10));
            formData.append("ortho_treatment", getEncryptedData(step4_quest1));
            formData.append("numbness", getEncryptedData(step4_quest2));
            formData.append("nasal", getEncryptedData(step4_quest3));
            formData.append("revision_operation", getEncryptedData(step4_quest4));
            formData.append("treatment", getEncryptedData(step5_quest1));
            formData.append("support", getEncryptedData(step5_quest2));
            formData.append("bite_change", getEncryptedData(yourBite));
            formData.append("appearance_change", getEncryptedData(yourAppearance));
            formData.append("education_impact", getEncryptedData(education_impact));
            formData.append("case_id", caseId);
            formData.append("user_id", userId);

            const response = await axios.post(`${Configure.apiUrl}/patients/update_history`, formData, {
                headers: {
                    Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.data.success == true) {
                setLoading(false)
                return true
            }


        } catch (error) {
            console.log("Error fetchning data: ", error)
            swal("", "Something Went Wrong!", "error");
            setLoading(false)
            return false
        }
    }

    function getFinalValue(values) {
        return (values ? Number(getDecryptedData(values)) : 0)
    }

    const converDataForAI = async () => {
        try {

            const response = await axios.post(`${Configure.apiUrl}/case/answers`, { caseId }, {
                headers: {
                    Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                }
            })

            const { data } = response.data

            const all_case_answers = data.map((question) => {
                return (
                    [
                        getFinalValue(question.feeling),
                        getFinalValue(question.worrying),
                        getFinalValue(question.conditions),
                        getFinalValue(question.pleasure),
                        getFinalValue(question.appearance),
                        getFinalValue(question.behaviours),
                        getFinalValue(question.activities),
                        getFinalValue(question.weight),
                        getFinalValue(question.teeth_position),
                        getFinalValue(question.teeth_bite),
                        getFinalValue(question.face),
                        getFinalValue(question.cheeks),
                        getFinalValue(question.lower_jaw),
                        getFinalValue(question.chin),
                        getFinalValue(question.cheek_bone),
                        getFinalValue(question.jaw_joint),
                        getFinalValue(question.night_snore),
                        getFinalValue(question.sleepy_feel),
                        getFinalValue(question.ortho_treatment),
                        getFinalValue(question.numbness),
                        getFinalValue(question.nasal),
                        getFinalValue(question.revision_operation),
                        getFinalValue(question.treatment),
                        getFinalValue(question.support)
                    ]
                )
            })

            const assessment_answers = data.map((question) => {
                return (
                    [
                        getFinalValue(question.iotn),
                        getFinalValue(question.ioftn),
                        getFinalValue(question.mdt_decision)
                    ]
                )
            })

            const current_case_answers = [
                [
                    Number(radioQuestion1),
                    Number(radioQuestion2),
                    Number(radioQuestion3),
                    Number(radioQuestion4),
                    Number(radioQuestion5),
                    Number(radioQuestion6),
                    Number(radioQuestion7),
                    Number(radioQuestion8),
                    Number(step3_quest1),
                    Number(step3_quest2),
                    Number(step3_quest3),
                    Number(step3_quest4),
                    Number(step3_quest5),
                    Number(step3_quest6),
                    Number(step3_quest7),
                    Number(step3_quest8),
                    Number(step3_quest9),
                    Number(step3_quest10),
                    Number(step4_quest1),
                    Number(step4_quest2),
                    Number(step4_quest3),
                    Number(step4_quest4),
                    Number(step5_quest1),
                    Number(step5_quest2)
                ]
            ]

            return ({
                array1: all_case_answers,
                array2: assessment_answers,
                array3: current_case_answers,
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getAIAnswers = async (data_to_sent) => {
        try {
            const response = await axios.post(`https://ai.facefacts.uk/api/predict`, data_to_sent, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    const proceedForAssessment = async () => {
        const nowOpenModal = await updateHistory()
        const data_for_ai = await converDataForAI()

        const final_answers = await getAIAnswers(data_for_ai)

        // console.log('Ai answers ', final_answers)

        let likes;
        if (caseData?.likes)
            likes = caseData?.likes?.split(',')
        else
            likes = '0,0,0'.split(',')

        // console.log('likes : ', likes)

        setLike1(Number(likes[0]))
        setLike2(Number(likes[1]))
        setLike3(Number(likes[2]))

        setAI_iotn({
            value: final_answers?.predicted_categories[0][0],
            text: first_2_options.find(item => item.value == final_answers?.predicted_categories[0][0]).label
        })

        setAI_ioftn({
            value: final_answers?.predicted_categories[1][0],
            text: first_2_options.find(item => item.value == final_answers?.predicted_categories[1][0]).label
        })

        setAI_mdt_decision({
            value: final_answers?.predicted_categories[2][0],
            text: third_options.find(item => item.value == final_answers?.predicted_categories[2][0]).label
        })

        setFeedBackModal(nowOpenModal)
    }

    // ===============================================

    async function handleSubmitFeedback(e) {
        e.preventDefault()
        try {
            setLoading(true)

            const formData = new FormData()

            formData.append("user_id", userId);
            formData.append("case_id", caseId);
            formData.append("iotn", getEncryptedData(iotn));
            formData.append("ioftn", getEncryptedData(ioftn));
            formData.append("mdt_decision", getEncryptedData(mdt_decision));
            formData.append("likes", `${like1},${like2},${like3}`);

            const response = await axios.post(`${Configure.apiUrl}/case/feedback`, formData, {
                headers: {
                    Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.data.success == true) {
                setLoading(false)
                setFeedBackModal(false)
                swal("Assessment Completed!", "Your Assessment Completed Successfully.", "success").then(() => {
                    navigate(-1)
                })

            }

        } catch (error) {
            console.log("Error Fetching data: ", error)
            swal("", "Something Went Wrong!", "error");
            setLoading(false)
        }
    }

    const setNewValue = (value, setValue) => {

        swal({
            title: "Are you sure ?",
            text: "You want to Change this data",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setValue(value)
                }
            });

        // setChangeValueNow(() => setValue(value))
    }

    return (
        <div>
            <div className="page-content">
                <Container fluid >
                    <Breadcrumb
                        title={"Dashboard"}
                        breadcrumbItem={"Verify " + ` (${caseData?.name}) ` + "Answers"}
                    />

                    <Row>
                        <Card className="px-0 mx-0">
                            <CardBody >
                                <div className="row">

                                    <div className="col-12 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">1. </span> Over the last two weeks, how often have you been feeling
                                            nervous, anxious or on edge?
                                        </p>

                                        <div className="d-flex">
                                            <div className="ms-4">
                                                <input type="radio" id="radio1" name="radio1" value={1} checked={radioQuestion1 == 1}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion1)} />
                                                <label htmlFor="radio1" className="ms-2">Not at All</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio2" name="radio1" value={2} checked={radioQuestion1 == 2}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion1)} />
                                                <label htmlFor="radio2" className="ms-2">Several Days</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio3" name="radio1" value={3} checked={radioQuestion1 == 3}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion1)} />
                                                <label htmlFor="radio3" className="ms-2">More than half Days</label>
                                            </div>

                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio4" name="radio1" value={4} checked={radioQuestion1 == 4}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion1)} />
                                                <label htmlFor="radio4" className="ms-2">Nearly Everyday</label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-12 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">2. </span> Over the last two weeks how often have you not been able to stop or control worrying?
                                        </p>

                                        <div className="d-flex">
                                            <div className="ms-4">
                                                <input type="radio" id="radio5" name="radio2" value={1} checked={radioQuestion2 == 1}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion2)} />
                                                <label htmlFor="radio5" className="ms-2">Not at All</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio6" name="radio2" value={2} checked={radioQuestion2 == 2}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion2)} />
                                                <label htmlFor="radio6" className="ms-2">Several Days</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio7" name="radio2" value={3} checked={radioQuestion2 == 3}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion2)} />
                                                <label htmlFor="radio7" className="ms-2">More than half Days</label>
                                            </div>

                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio8" name="radio2" value={4} checked={radioQuestion2 == 4}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion2)} />
                                                <label htmlFor="radio8" className="ms-2">Nearly Everyday</label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-12 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">3    . </span>
                                            Over the last two weeks how often have you been feeling down,
                                            depressed or hopeless?
                                        </p>

                                        <div className="d-flex">
                                            <div className="ms-4">
                                                <input type="radio" id="radio9" name="radio3" value={1} checked={radioQuestion3 == 1}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion3)} />
                                                <label htmlFor="radio9" className="ms-2">Not at All</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio10" name="radio3" value={2} checked={radioQuestion3 == 2}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion3)} />
                                                <label htmlFor="radio10" className="ms-2">Several Days</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio11" name="radio3" value={3} checked={radioQuestion3 == 3}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion3)} />
                                                <label htmlFor="radio11" className="ms-2">More than half Days</label>
                                            </div>

                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio12" name="radio3" value={4} checked={radioQuestion3 == 4}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion3)} />
                                                <label htmlFor="radio12" className="ms-2">Nearly Everyday</label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-12 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">4. </span>
                                            Over the last two weeks how often have you been feeling little
                                            interest or pleasure in doing things
                                        </p>

                                        <div className="d-flex">
                                            <div className="ms-4">
                                                <input type="radio" id="radio13" name="radio4" value={1} checked={radioQuestion4 == 1}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion4)} />
                                                <label htmlFor="radio13" className="ms-2">Not at All</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio14" name="radio4" value={2} checked={radioQuestion4 == 2}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion4)} />
                                                <label htmlFor="radio14" className="ms-2">Several Days</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio15" name="radio4" value={3} checked={radioQuestion4 == 3}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion4)} />
                                                <label htmlFor="radio15" className="ms-2">More than half Days</label>
                                            </div>

                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="radio16" name="radio4" value={4} checked={radioQuestion4 == 4}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion4)} />
                                                <label htmlFor="radio16" className="ms-2">Nearly Everyday</label>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </CardBody>
                        </Card>

                        <Card className="px-0 mx-0">
                            <CardBody >
                                <div className="row">

                                    {/* ================================================= */}

                                    <div className="col-12 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">5. </span>
                                            Do you spend an hour or more everyday worrying about your
                                            appearance?
                                        </p>

                                        <div className="d-flex">
                                            <div className="ms-4">
                                                <input type="radio" id="yes1" name="yesno1" value={1} checked={radioQuestion5 == 1}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion5)} />
                                                <label htmlFor="yes1" className="ms-2">Yes</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="no1" name="yesno1" value={2} checked={radioQuestion5 == 2}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion5)} />
                                                <label htmlFor="no1" className="ms-2">No</label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-12 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">6. </span>
                                            Do you find yourself carrying out lots of behaviours (e.g.
                                            mirror checking, grooming) and/or mental acts (e.g. comparing
                                            yourself with others) in an effort to cope with your
                                            appearance worries?
                                        </p>

                                        <div className="d-flex">
                                            <div className="ms-4">
                                                <input type="radio" id="yes2" name="yesno2" value={1} checked={radioQuestion6 == 1}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion6)} />
                                                <label htmlFor="yes2" className="ms-2">Yes</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="no2" name="yesno2" value={2} checked={radioQuestion6 == 2}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion6)} />
                                                <label htmlFor="no2" className="ms-2">No</label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-12 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">7. </span>
                                            Do your appearance worries make you feel miserable (e.g.
                                            anxious, depressed, ashamed) and/or get in the way of daily
                                            activities (e.g. socialising, school, work)?
                                        </p>

                                        <div className="d-flex">
                                            <div className="ms-4">
                                                <input type="radio" id="yes3" name="yesno3" value={1} checked={radioQuestion7 == 1}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion7)} />
                                                <label htmlFor="yes3" className="ms-2">Yes</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="no3" name="yesno3" value={2} checked={radioQuestion7 == 2}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion7)} />
                                                <label htmlFor="no3" className="ms-2">No</label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-12 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">8. </span>
                                            Are your appearance concerns focussed on your weight?
                                        </p>

                                        <div className="d-flex">
                                            <div className="ms-4">
                                                <input type="radio" id="yes4" name="yesno4" value={1} checked={radioQuestion8 == 1}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion8)} />
                                                <label htmlFor="yes4" className="ms-2">Yes</label>
                                            </div>
                                            <div className="ms-5 ps-4">
                                                <input type="radio" id="no4" name="yesno4" value={2} checked={radioQuestion8 == 2}
                                                    onClick={(e) => setNewValue(e.target.value, setRadioQuestion8)} />
                                                <label htmlFor="no4" className="ms-2">No</label>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </CardBody>
                        </Card>

                        <Card className="px-0 mx-0">
                            <CardBody >
                                <div className="row">

                                    {/* ================================================== */}

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">9. </span>
                                            Do you feel you have any problems with biting or chewing
                                            related to position of your teeth?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest1}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques1)}
                                                />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest1}</span>
                                            </div>
                                            <StatusBadge text1={'1 Being No Problems'} text2={'10 Very Problematic'} />
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">10. </span>
                                            How do you feel about the appearance of your teeth and bite?
                                        </p>

                                        <div className="mt-md-4 pt-md-2">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest2}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques2)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest2}</span>
                                            </div>
                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">11. </span>
                                            How do you feel about the appearance of your face?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest3}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques3)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest3}</span>
                                            </div>
                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">12. </span>
                                            How do you feel about the appearance of your upper jaw/
                                            cheeks?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest4}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques4)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest4}</span>
                                            </div>
                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">13. </span>
                                            How do you feel about the appearance of your lower jaw?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest5}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques5)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest5}</span>
                                            </div>
                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">14. </span>
                                            How do you feel about the appearance of your chin?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest6}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques6)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest6}</span>
                                            </div>
                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">15. </span>
                                            How do you feel about the appearance of your cheek bones?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest7}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques7)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest7}</span>
                                            </div>
                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">16. </span>
                                            Do you have any jaw joint problems?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest8}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques8)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest8}</span>
                                            </div>
                                            <StatusBadge text1={'1 No Problems'} text2={'10 Very Problematic'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">17. </span>
                                            Do you snore at night?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest9}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques9)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest9}</span>
                                            </div>
                                            <StatusBadge text1={'1 Never'} text2={'10 Every Night'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">18. </span>
                                            Do you feel sleepy during the day after a full night’s sleep?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step3_quest10}
                                                    onChange={(e) => setNewValue(e.target.value, setStep3Ques10)} />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest10}</span>
                                            </div>
                                            <StatusBadge text1={'1 Not Sleepy At All'} text2={'10 Very Sleepy'} />

                                        </div>

                                    </div>

                                </div>
                            </CardBody>
                        </Card>

                        {/* ================================================== */}

                        <Card className="px-0 mx-0">
                            <CardBody >
                                <div className="row">

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">19. </span>
                                            Orthodontic treatment time can be 2-3 years. If this was
                                            prolonged how much would this concern you?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step4_quest1}
                                                    onChange={(e) => setNewValue(e.target.value, setStep4Ques1)}
                                                />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step4_quest1}</span>
                                            </div>
                                            <StatusBadge text1={'1 Not concerned'} text2={'10 Very concerned'} />

                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">20. </span>
                                            If you have permanent numbness or altered sensation of your lips, chin and / or tongue following surgery how much would this concern you?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step4_quest2}
                                                    onChange={(e) => setNewValue(e.target.value, setStep4Ques2)}
                                                />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step4_quest2}</span>
                                            </div>
                                            <StatusBadge text1={'1 Not concerned'} text2={'10 Very concerned'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">21. </span>
                                            If your nose appearance was made worse after surgery how much
                                            would this concern you?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step4_quest3}
                                                    onChange={(e) => setNewValue(e.target.value, setStep4Ques3)}
                                                />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step4_quest3}</span>
                                            </div>
                                            <StatusBadge text1={'1 Not concerned'} text2={'10 Very concerned'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">22. </span>
                                            Up to 10% of patients may need a revision operation. How much
                                            would another operation concern you?
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step4_quest4}
                                                    onChange={(e) => setNewValue(e.target.value, setStep4Ques4)}
                                                />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step4_quest4}</span>
                                            </div>
                                            <StatusBadge text1={'1 Not concerned'} text2={'10 Very concerned'} />

                                        </div>

                                    </div>

                                </div>
                            </CardBody>
                        </Card>

                        <Card className="px-0 mx-0">
                            <CardBody >
                                <div className="row">

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">23. </span>
                                            How much do you want the treatment
                                        </p>

                                        <div className="mt-md-4 pt-md-2">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step5_quest1}
                                                    onChange={(e) => setNewValue(e.target.value, setStep5Ques1)}
                                                />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step5_quest1}</span>
                                            </div>
                                            <StatusBadge text1={'1 Not at all'} text2={'10 Very much'} />

                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">24. </span>
                                            Treatment may involve multiple visits with possible surgery.
                                            Are you satisfied you have adequate support at home to
                                            complete treatment
                                        </p>

                                        <div className="">
                                            <div className="d-flex align-items-center ms-4">
                                                <input type="range" min={0} max={10} value={step5_quest2}
                                                    onChange={(e) => setNewValue(e.target.value, setStep5Ques2)}
                                                />
                                                <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step5_quest2}</span>
                                            </div>
                                            <StatusBadge text1={'1 Not support'} text2={'10 Lots of support'} />

                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">25. </span>
                                            When considering jaw surgery What do you want to change about
                                            your <br /> bite ?
                                        </p>

                                        <div className="">
                                            <textarea rows="2" placeholder="Enter Your Answer"
                                                value={yourBite}
                                                onChange={(e) => setYourBite(e.target.value)}
                                                className="form-control"></textarea>
                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">26. </span>
                                            When considering jaw surgery What do you want to change about
                                            your appearance?
                                        </p>

                                        <div className="">
                                            <textarea rows="2" placeholder="Enter Your Answer"
                                                value={yourAppearance}
                                                onChange={(e) => setYourAppearance(e.target.value)}
                                                className="form-control"></textarea>
                                        </div>

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <p className="d-flex" style={{ color: "#005eb8" }}>
                                            <span className="fw-bold me-2">27. </span>
                                            How much impact will treatment have on education / work?
                                        </p>

                                        <div className="">
                                            <textarea rows="2" placeholder="Enter Your Answer"
                                                value={education_impact}
                                                onChange={(e) => setEducationImpact(e.target.value)}
                                                className="form-control"></textarea>
                                        </div>

                                    </div>

                                </div>

                                <div className="text-end">
                                    <button onClick={proceedForAssessment} className="btn btn-primary bg-blue">
                                        {
                                            loading ?
                                                <i className="bx bx-hourglass bx-spin align-middle me-2"></i> :
                                                <i className="bx bx-send me-2"></i>
                                        }
                                        Submit Assessment
                                    </button>
                                </div>
                            </CardBody>
                        </Card>

                    </Row>
                </Container>
            </div>

            <Modal size="md" isOpen={feedBackModal} centered={true}>
                <div className="modal-content">
                    <div className="modal-header py-3 px-4 border-bottom bg-light-purple">
                        <h5 className="modal-title mt-0 text-purple mb-0 ">
                            {/* <img src={profileImg} alt="" className='me-3' style={{ width: 45 }} /> */}
                            Objective Clinical Indication</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setFeedBackModal(false)
                            }}
                            className="btn-modal-close"
                        >
                            <div aria-hidden="true" className="btn-modal-span text-blue px-2 py-3 rounded-circle bg-primary bg-soft"> &times; </div>
                        </button>
                    </div>
                    <ModalBody className="px-4 pb-4">
                        <form onSubmit={handleSubmitFeedback}>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label htmlFor="" className="fs-5">Index of Orthodontic Treatment Needed (IOTN)</label>
                                    {
                                        like1 === 2 &&
                                        <select className="form-select" value={iotn} onChange={(e) => setIotn(e.target.value)} required>
                                            <option value="">-- Please Select --</option>
                                            {
                                                first_2_options.map(item => {
                                                    return (
                                                        <option key={item.value} value={item.value}>{item.label}</option>
                                                    )
                                                })
                                            }
                                        </select>

                                    }

                                    <AiCard answer={AI_iotn.text}
                                        like={like1}
                                        setLike={setLike1}
                                        setValue={setIotn}
                                        answerValue={AI_iotn.value}
                                    />
                                </div>

                                <div className="col-12 mb-3">
                                    <label htmlFor="" className="fs-5">Index of Orthognathic Functional Treatment Need (IOFTN)</label>
                                    {
                                        like2 === 2 &&
                                        <select className="form-select" value={ioftn} onChange={(e) => setIoftn(e.target.value)} required>
                                            <option value="">-- Please Select --</option>
                                            {
                                                first_2_options.map(item => {
                                                    return (
                                                        <option key={item.value} value={item.value}>{item.label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    }
                                    <AiCard answer={AI_ioftn.text}
                                        like={like2}
                                        setLike={setLike2}
                                        setValue={setIoftn}
                                        answerValue={AI_ioftn.value}
                                    />
                                </div>

                                <div className="col-12 mb-3 mt-3">
                                    <label htmlFor="" className="fs-5">MDT decision</label>

                                    {
                                        like3 === 2 &&
                                        <div className="d-flex ">
                                            {
                                                third_options.map(item => {
                                                    return (
                                                        <div key={item.value} className={item.value !== 1 && "ms-4"}>
                                                            <input type="radio" name="feedback" id={`feed1${item.value}`}
                                                                onClick={() => setMtdDecision(item.value)}
                                                                checked={mdt_decision == item.value}
                                                                required
                                                            /> <label htmlFor={`feed1${item.value}`}>{item.label}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                    <AiCard answer={AI_mdt_decision.text}
                                        like={like3}
                                        setLike={setLike3}
                                        setValue={setMtdDecision}
                                        answerValue={AI_mdt_decision.value}
                                    />

                                </div>

                                <div className="mt-4 text-center">
                                    <button type="submit" className="btn btn-primary bg-blue">
                                        {
                                            loading ?
                                                <> <i className="bx bx-hourglass bx-spin align-middle me-2"></i> Submitting.. </> :
                                                <> <i className="fas fa-save me-2"></i> Submit Feedback </>
                                        }
                                    </button>
                                    <button type='button' onClick={(e) => setFeedBackModal(false)} className="btn btn-secondary bg-dark ms-3">
                                        <i className="fas fa-times me-2"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}


function AiCard({ answer, like, setLike, setValue, answerValue }) {
    return (
        <div className="d-flex justify-content-between align-items-center rounded bg-light py-2 px-3 mt-2">
            <div>
                <p className="text-uppercase text-blue mb-2 fw-bold ">Ai generated suggession</p>
                <h6 className="mb-0 fs-5"> {answer} </h6>
            </div>

            <div className="d-flex justify-content-between align-items-center">
                <div className="like-btn me-2"
                    onClick={() => {
                        const result = like !== 1 ? 1 : 0
                        setLike(result)
                        setValue(result === 1 ? answerValue : '')
                    }}
                ><i className={`bi bi-hand-thumbs-up${like === 1 ? '-fill' : ''} text-success`}></i></div>

                <div className="like-btn"
                    onClick={() => {
                        setLike(like !== 2 ? 2 : 0)
                        setValue('')
                    }}
                ><i className={`bi bi-hand-thumbs-down${like === 2 ? '-fill' : ''} text-danger`}></i></div>
            </div>
        </div>
    )
}

export default VerifyCase
