import { Configure } from "pages/configure";
import React from "react";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Authmiddleware = (props) => {
  if (!secureLocalStorage.getItem("admin") && !secureLocalStorage.getItem("adminId") && !secureLocalStorage.getItem("token")) {
    return (
      <Navigate to={{ pathname: `${Configure.appUrl}login`, state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
