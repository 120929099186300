import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import profileImg from "../../assets/images/jrh_user.png"
import { Configure, getDecryptedData, getEncryptedData } from 'pages/configure';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

const AdminProfile = ({ modal, setModal }) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [show_pwd, setShowPwd] = useState(false)

    // state for errors messages
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        oldPassword: '',
        password: ''
    });

    useEffect(() => {
        const formData = new FormData()
        formData.append("user_id", secureLocalStorage.getItem("adminId"))
        axios.post(`${Configure.apiUrl}/profile`, formData, {
            headers: {
                Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            setName(response.data.data[0].name)
            setEmail(response.data.data[0].email)

        }).catch((error) => {
            console.log(error)
        })
    }, [modal])

    // validations for first form
    const handleValidation = () => {

        const newErrors = {};

        if (!name) {
            newErrors.name = 'Patient name is required!';
        }

        if (!email) {
            newErrors.email = 'Email is required!';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            newErrors.email = 'Invalid email format';
        }

        if (password.length > 0 && password.length !== 8) {
            newErrors.password = 'Password must be of 8 characters!';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    // ===========================

    // step 1 api calling
    const handleSaveProfile = async (e) => {
        e.preventDefault()
        try {
            if (handleValidation()) {
                setLoading(true)
                const formData = new FormData()
                formData.append("name", name);
                // formData.append("email", email);
                formData.append("old_password", oldPassword);
                formData.append("password", password);
                formData.append("user_id", secureLocalStorage.getItem("adminId"))

                const response = await axios.post(`${Configure.apiUrl}/profile/update`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                // if (response.data.data == "Password do not match.") {
                //     const newErrors = {}
                //     newErrors.oldPassword = 'Old Password is Invalid!';
                //     setErrors(newErrors)
                //     setLoading(false)
                //     return;
                // }

                if (response.data.success == true) {
                    secureLocalStorage.setItem("admin", response.data.data[0].name)
                    setLoading(false)
                    swal("", "Profile Updated Successfully", "success")
                    setOldPassword("")
                    setPassword("")
                }


            }

        } catch (error) {
            console.log('Error fetching data: ', error);
            setLoading(false)
            swal("", 'Something went wrong!', "error")
        }
    }


    return (
        <Modal size="md" isOpen={modal} centered={true}>

            <div className="modal-content">
                <div className="modal-header py-3 px-4 border-bottom bg-light-purple">
                    <h5 className="modal-title mt-0 text-purple mb-0 d-flex align-items-center">
                        <img src={profileImg} alt="" className='me-3' style={{ width: 45 }} />
                        Admin Profile</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModal(false)
                        }}
                        className="btn-modal-close"
                    >
                        <div aria-hidden="true" className="btn-modal-span text-blue px-2 py-3 rounded-circle bg-primary bg-soft"> &times; </div>
                    </button>
                </div>
                <ModalBody className="px-4 pb-4">
                    <form onSubmit={handleSaveProfile} autoComplete="off">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <label htmlFor="">Name</label>
                                <input type="text" className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter Patient Name" />
                                {errors.name && <span className="text-danger">{errors.name}</span>}
                            </div>

                            <div className="col-12 mb-3">
                                <label htmlFor="">Email Address</label>
                                <input type="text" className="form-control"
                                    value={email}
                                    // onChange={(e) => setEmail(e.target.value)}
                                    readOnly
                                    placeholder="Enter Email Address" />
                                {errors.email && <span className="text-danger">{errors.email}</span>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="">Old Password</label>
                                <input type={"password"} className="form-control"
                                    value={"********"}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    placeholder="Enter Password" />
                                {errors.oldPassword && <span className="text-danger">{errors.oldPassword}</span>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="">New Password</label>
                                <input type={show_pwd ? "text" : "password"} className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter Password" />
                                {errors.password && <span className="text-danger">{errors.password}</span>}
                            </div>

                            <div className='col-12'>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customControlInline"
                                        onChange={(e) => setShowPwd(e.target.checked)}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="customControlInline"
                                    >
                                        Show Password
                                    </label>
                                </div>
                            </div>

                            <div className="mt-4 text-center">
                                <button type="submit" className="btn btn-primary bg-blue">

                                    {
                                        loading ?
                                            <> <i className="bx bx-hourglass bx-spin align-middle me-2"></i> Saving.. </> :
                                            <> <i className="fas fa-save me-2"></i> Save </>
                                    }

                                </button>
                                <button type='button' onClick={(e) => setModal(false)} className="btn btn-secondary bg-dark ms-3">
                                    <i className="fas fa-times me-2"></i>
                                    Close
                                </button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default AdminProfile