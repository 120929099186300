import PropTypes from "prop-types";
import React from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, Label } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";

// import css
import '../../assets/css/Login/Login.css';
import { Configure, getDecryptedData } from "pages/configure";

import axios from "axios"
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";

const Login = props => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [show_pwd, setShowPwd] = useState(false)

  //meta title
  document.title = "Login | John Radcliffe Hospital ";

  const navigate = useNavigate()

  useEffect(() => {
    if (secureLocalStorage.getItem("admin") && secureLocalStorage.getItem("adminId") && secureLocalStorage.getItem("token")) {
      navigate(`${Configure.appUrl}dashboard`)
    }
  }, [])

  const handleLoginAdmin = async (e) => {
    e.preventDefault()
    try {

      setLoading(true)

      const formData = new FormData()
      formData.append("email", email)
      formData.append("password", password)

      const response = await axios.post(`${Configure.apiUrl}/admin/login`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      // console.log("Login data: ", response)
      setLoading(false)

      if (response.data.success == false) {
        setError(true)
        return;
      }

      if (response.data.success == true && response.data.message == "User login successful.") {
        secureLocalStorage.setItem("admin", response.data.data.name);
        secureLocalStorage.setItem("adminId", response.data.data.id);
        secureLocalStorage.setItem("token", response.data.data.api_token);
        navigate(`${Configure.appUrl}dashboard`)
      }


    } catch (error) {
      setError(true)
      console.log('Error: ', error);
      setLoading(false)
    }
  }



  return (
    <React.Fragment>
      <div className="login-page login-bg bg-dark mt-0">

        <div className="account-pages pt-sm-5 h-100">
          <Container className="h-100">
            <Row className="justify-content-end h-100 align-items-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={8}>
                        <div className="text-blue p-4">
                          <h5 className="text-blue fw-bold">Welcome Back !</h5>
                          <p style={{ fontWeight: 500 }}>Sign in to continue to FaceFacts</p>
                        </div>
                      </Col>
                      <Col className="col-4 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to={`${Configure.appUrl}`} className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2 mt-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={handleLoginAdmin}
                        autoComplete="off"
                      >
                        {error ? <Alert color="danger">Please enter correct email or password</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            autoComplete="off"
                            value={email}
                            onChange={(e) => {
                              error && setError(false)
                              setEmail(e.target.value)
                            }}
                            required
                          />
                        </div>

                        {/* <div className="d-flex justify-content-between mb-3">
                          <div className="status-badge px-3 py-1 bg-light rounded-pill text-capitalize text-success">
                            1 very happy
                          </div>
                          <div className="status-badge px-3 py-1 bg-light rounded-pill text-capitalize text-danger">
                            2 very unhappy
                          </div>
                        </div> */}

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type={show_pwd ? "text" : "password"}
                            placeholder="Enter Password"
                            autoComplete="off"
                            value={password}
                            onChange={(e) => {
                              error && setError(false)
                              setPassword(e.target.value)
                            }}
                            required
                          />
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            onChange={(e) => setShowPwd(e.target.checked)}

                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Show Password
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary bg-blue btn-block"
                            type="submit"
                          >
                            {
                              loading &&
                              <i className="bx bx-hourglass bx-spin align-middle me-2"></i>
                            }
                            {loading ? "Logging In.." : "Log In"}
                          </button>
                        </div>

                        <div className="text-center my-4">
                          <Link to={`${Configure.appUrl}forgot-password`} className="text-blue">Forgot Your Password ?</Link>
                        </div>

                        <div className=" text-center">
                          <p>
                            © {new Date().getFullYear()} FaceFacts. Crafted with{" "}
                            <i className="mdi mdi-heart text-danger" /> by Zithas Technologies
                          </p>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
