import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"
import "../../assets/css/Layout/Header.css"
//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { Configure } from "pages/configure"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [setting, setSetting] = useState(false)
  const [expense, setExpense] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav ps-1 pe-0" style={{ zIndex: "1001" }}>
        <div className="container-fluid px-0">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav d-flex justify-content-between w-100">
                <div className="d-flex">
                  {/* dashboard */}
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${Configure.appUrl}dashboard`}
                    >
                      <i className="fas fa-home me-2 " style={{color: '#89bced'}}></i>
                      {props.t("Dashboard")} {props.menuOpen}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${Configure.appUrl}surgeons`}
                    >
                      <i className="fas fa-user-md me-2 " style={{color: '#89bced'}}></i>
                      {props.t("Surgeon")} {props.menuOpen}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${Configure.appUrl}patients`}
                    >
                      <i className="fas fa-hospital-user me-2 " style={{color: '#89bced'}}></i>
                      {props.t("Patients")} {props.menuOpen}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${Configure.appUrl}cases`}
                    >
                      <i className="fas fa-address-card me-2 " style={{color: '#89bced'}}></i>
                      {props.t("Cases")} {props.menuOpen}
                    </Link>
                  </li>
          </div>
              
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
