import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © FaceFacts.</Col>
            <Col md={6} className="text-md-end">Designed by <a href="https://www.zithas.co.uk" className="fw-bold text-blue" target="_blank" rel="noreferrer">Zithas Technologies</a> </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
