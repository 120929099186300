import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Container, CardBody, Card, ModalBody, Modal } from "reactstrap"
//redux
import SearchFilter from "components/Common/SearchFilter"
import Breadcrumb from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

import swal from 'sweetalert';
import axios from "axios"
import Loader from "pages/Loader"
import secureLocalStorage from "react-secure-storage"
import { Configure, getDecryptedData, getEncryptedData } from "pages/configure"

const AllPatients = () => {

    const [allPatients, setAllPatients] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [hospitalNo, setHospitalNo] = useState('');
    const [patientName, setPatientName] = useState('');
    const [nhsNumber, setNhsNumber] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [location, setLocation] = useState('');
    const [password, setPassword] = useState('');

    const [patientId, setpatientId] = useState('')

    const [passwordType, setPasswordType] = useState("password")

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return
        }
        setPasswordType("password")
    }


    const navigate = useNavigate()

    useEffect(() => {
        async function getAllPatients() {
            try {
                setLoading(true)
                const response = await axios.get(`${Configure.apiUrl}/patients`, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                // console.log(response.data.data)

                const finalData = response.data.data.filter((temp) => temp.type == 2)
                    .map((temp) => {
                        return ({
                            ...temp,
                            name: getDecryptedData(temp.name),
                            phone: getDecryptedData(temp.phone),
                            location: getDecryptedData(temp.location),
                            dob: getDecryptedData(temp.dob),
                            hospital_number: getDecryptedData(temp.hospital_number),
                            nhs_number: temp.nhs_number,
                            // email: getDecryptedData(temp.email)
                        })
                    })
                // console.log('finalData', finalData)
                setAllPatients(finalData)
                setLoading(false)
            } catch (error) {
                console.log("Error Fetching data: ", error)
            }
        }
        getAllPatients()
    }, [])

    document.title = "Patients | John Radcliffe Hospital"

    const onCandidateDelete = (candidate_id, company_id) => {
        swal({
            title: "Are you sure?",
            text: "You really want to delete this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    // setIsLoading(true)
                    // const formData = new FormData()
                    // formData.append("candidate_id", candidate_id)
                    // formData.append("company_id", company_id)

                    // axios.post('https://ztpl.net/jobfitmeter/backend/api/superadmin_delete_candidates', formData, {
                    //     header: {
                    //         Accept: "application/json",
                    //         "Content-Type": "multipart/form-data",
                    //     }
                    // })
                    //     .then((response) => {
                    //         swal("Candidate has been deleted successfully!", {
                    //             icon: "success",
                    //         })
                    //         console.log(response.data.data)
                    //         setAllPatients(response.data.data)

                    //         setIsLoading(false)
                    //     })
                    //     .catch((error) => {
                    //         console.log(error)
                    //         swal("Something Went Wrong", "", "error")
                    //         setIsLoading(false)
                    //     })

                }
            });
    }

    const changeStatus = (user_id, status) => {
        swal({
            title: "Are you sure?",
            text: `you want to ${status == 1 ? "InActive" : "Active"} this Patient`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const formData = new FormData()
                    formData.append("user_id", user_id)
                    formData.append("status", status == 1 ? 0 : 1)

                    axios.post(`${Configure.apiUrl}/user/status`, formData, {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                            "Content-Type": "multipart/form-data",
                        }
                    }).then((response) => {
                        const finalData = response.data.data.filter((temp) => temp.type == 2)
                            .map((temp) => {
                                return ({
                                    ...temp,
                                    name: getDecryptedData(temp.name),
                                    phone: getDecryptedData(temp.phone),
                                    location: getDecryptedData(temp.location),
                                    dob: getDecryptedData(temp.dob),
                                    hospital_number: getDecryptedData(temp.hospital_number),
                                    nhs_number: temp.nhs_number,
                                    // email: getDecryptedData(temp.email)
                                })
                            })

                        setAllPatients(finalData)
                    })
                } else {
                    setAllPatients((prevData) => [...prevData])
                }
            });
    }

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                className: "col-1 text-center",
                Cell: cellProps => {
                    return (
                        <span>{cellProps.row.index + 1}</span>
                    )
                },
            },
            {
                Header: "Patient Name",
                accessor: "name",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "NHS Number",
                accessor: "nhs_number",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Email Address",
                accessor: "email",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Phone Number",
                accessor: "phone",
                disableFilters: true,
                filterable: true,
                className: "text-center"
            },
            {
                Header: "Location",
                accessor: "location",
                disableFilters: true,
                filterable: true,
                className: "text-center",
            },
            {
                Header: "Status",
                accessor: "status",
                disableFilters: true,
                filterable: true,
                className: "text-center",
                Cell: cellProps => {
                    return (
                        <div className="text-center">
                            <div className="form-switch form-switch-md">
                                <input
                                    type="checkbox"
                                    className="form-check-input second_view"
                                    onChange={() =>
                                        changeStatus(cellProps.row.original.id, cellProps.row.original.is_active)}
                                    style={{ cursor: 'pointer' }}
                                    defaultChecked={cellProps.row.original.is_active == 1 ? true : false}
                                />
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: "Action",
                disableFilters: true,
                accessor: "view",
                className: "text-center",
                Cell: cellProps => {
                    const candidate_id = cellProps.data[cellProps.row.index].id
                    const company_id = cellProps.data[cellProps.row.index].company_id
                    return (
                        <div>
                            <Link
                                to={`${Configure.appUrl}patients/cases`}
                                state={{
                                    data: {
                                        nhs_number: cellProps.row.original.nhs_number,
                                        user_id: cellProps.row.original.id
                                    }
                                }}
                                className="btn btn-outline-dark btn-sm"
                            >
                                <i className="fas fa-hospital-user me-1"></i> Cases
                            </Link>

                            <button
                                onClick={(e) => {
                                    setHospitalNo(cellProps.row.original.hospital_number)
                                    setPatientName(cellProps.row.original.name)
                                    setNhsNumber(cellProps.row.original.nhs_number)
                                    setEmail(cellProps.row.original.email)
                                    setPhone(cellProps.row.original.phone)
                                    setBirthDate(cellProps.row.original.dob)
                                    setLocation(cellProps.row.original.location)
                                    setpatientId(cellProps.row.original.id)
                                    setEditModal(true)
                                }}
                                className="btn btn-outline-info btn-sm ms-2"
                            >
                                <i className="fas fa-pen me-1"></i> Edit
                            </button>

                            {/* <button
                                onClick={() => onCandidateDelete(candidate_id, company_id)}
                                className="btn btn-danger btn-sm ml-10"
                            >
                                <i className="fas fa-trash-alt"></i>
                            </button> */}
                        </div>
                    )
                },
            },
        ],
        [allPatients]
    )

    const data = useMemo(() => allPatients, [allPatients])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    )

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    // Add new patient
    // state for errors messages
    const [errors, setErrors] = useState({
        hospitalNo: '',
        patientName: '',
        nhsNumber: '',
        email: '',
        password: '',
        phone: "",
        birthDate: "",
        location: ""
    });

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrors({})
        }

    }, [hospitalNo, patientName, nhsNumber, email, password, phone, birthDate, location])

    // validations for first form
    const handleValidation = (isPassword) => {

        const newErrors = {};

        if (!hospitalNo) {
            newErrors.hospitalNo = 'Hospital no. is required!';
        }

        if (!patientName) {
            newErrors.patientName = 'Patient name is required!';
        }

        if (!nhsNumber) {
            newErrors.nhsNumber = 'NHS number is required!';
        } else if (!(/^[0-9]*$/).test(nhsNumber)) {
            newErrors.nhsNumber = "Only 0-9 digits allowed";
        } else if (nhsNumber.length > 10 || nhsNumber.length < 10) {
            newErrors.nhsNumber = 'NHS number must be of 10 digits';
        }

        if (!birthDate) {
            newErrors.birthDate = 'Birth date is required!';
        }

        if (!location) {
            newErrors.location = 'Location is required!';
        } else if ((/\d/).test(location)) {
            newErrors.location = 'Location can only contain letters';
        }

        if (!email) {
            newErrors.email = 'Email is required!';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            newErrors.email = 'Invalid email format';
        }

        if (isPassword) {
            if (!password || password.length != 8) {
                newErrors.password = '8 length password is required!';
            }
        }

        if (!phone) {
            newErrors.phone = 'Phone number is required!';
        }
        else if (!(/^[0-9]*$/).test(phone)) {
            newErrors.phone = "Only 0-9 digits allowed";
        }
        else if (phone.length < 7 || phone.length > 15) {
            newErrors.phone = 'Invalid Phone Number Length';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    // ===========================

    // Register Patient
    const handleRegisterPatient = async (e) => {
        e.preventDefault()
        try {
            if (handleValidation(true)) {
                setLoading(true)
                const formData = new FormData()

                formData.append("hospital_number", getEncryptedData(hospitalNo));
                formData.append("name", getEncryptedData(patientName));
                formData.append("nhs_number", nhsNumber);
                formData.append("phone", getEncryptedData(phone));
                formData.append("birth_date", getEncryptedData(birthDate));
                formData.append("location", getEncryptedData(location));
                formData.append("password", password);
                formData.append("email", email);

                const response = await axios.post(`${Configure.apiUrl}/patient/signup`, formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                if (response.data.success == false && response.data.message == "NHS already exists") {
                    swal("", response.data.message, "warning");
                    setLoading(false)
                    return;
                }

                if (response.data.data == "Email id is already exists...") {
                    swal("", "This Email Already Exist!", "warning")
                    setLoading(false)
                    return;
                }

                // console.log(response)

                const finalData = response.data.data.filter((temp) => temp.type == 2)
                    .map((temp) => {
                        return ({
                            ...temp,
                            name: getDecryptedData(temp.name),
                            phone: getDecryptedData(temp.phone),
                            location: getDecryptedData(temp.location),
                            dob: getDecryptedData(temp.dob),
                            hospital_number: getDecryptedData(temp.hospital_number),
                            nhs_number: temp.nhs_number,
                            // email: getDecryptedData(temp.email)
                        })
                    })

                setAllPatients(finalData)

                if (response.data.success == true) {
                    setLoading(false)
                    setAddModal(false)
                    setHospitalNo("")
                    setPatientName("")
                    setNhsNumber("")
                    setEmail("")
                    setPhone("")
                    setBirthDate("")
                    setLocation("")
                    setPassword("")

                    swal("", "Patient Registered Successfully!", "success")
                }
            }

        } catch (error) {
            console.log('Error fetching data: ', error);
            swal("", "Something Went Wrong", "error")
            setLoading(false)
        }
    }

    const handleEditPatient = async (e) => {
        e.preventDefault()
        try {
            if (handleValidation(false)) {
                setLoading(true)
                const formData = new FormData()

                formData.append("hospital_number", getEncryptedData(hospitalNo));
                formData.append("name", getEncryptedData(patientName));

                formData.append("nhs_number", nhsNumber);

                formData.append("phone", getEncryptedData(phone));
                formData.append("birth_date", getEncryptedData(birthDate));
                formData.append("location", getEncryptedData(location));
                // formData.append("email", email);
                formData.append("user_id", patientId);

                const response = await axios.post(`${Configure.apiUrl}/patient/update`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                if (response.data.success == false && response.data.data == "NHS already exists") {
                    swal("", response.data.data, "warning");
                    setLoading(false)
                }
                else {

                    const finalData = response.data?.data?.filter((temp) => temp.type == 2)
                        .map((temp) => {
                            return ({
                                ...temp,
                                name: getDecryptedData(temp.name),
                                phone: getDecryptedData(temp.phone),
                                location: getDecryptedData(temp.location),
                                dob: getDecryptedData(temp.dob),
                                hospital_number: getDecryptedData(temp.hospital_number),
                                nhs_number: temp.nhs_number,
                                // email: getDecryptedData(temp.email)
                            })
                        })

                    setAllPatients(finalData)

                    if (response.data.success == true) {
                        setLoading(false)
                        setEditModal(false)
                        setHospitalNo("")
                        setPatientName("")
                        setNhsNumber("")
                        setEmail("")
                        setPhone("")
                        setBirthDate("")
                        setLocation("")

                        swal("", "Patient Updated Successfully!", "success")
                    }
                }

            }

        } catch (error) {
            console.log('Error fetching data: ', error);
            swal("", "Something Went Wrong", "error")
            setLoading(false)
        }
    }

    return (
        <div>
            {
                loading && <Loader />
            }
            <div className="page-content">
                <Container fluid >
                    <Breadcrumb
                        title={"Dashboard"}
                        breadcrumbItem={"All Patients"}
                    />

                    <Row>
                        <Card className="px-0 mx-0">
                            <CardBody >

                                <Row className="mb-3 justify-space-between flex-md-row flex-column-reverse">
                                    <Col md={2}>
                                        <select
                                            className="form-select d-md-block d-none"
                                            value={pageSize}
                                            onChange={onChangeInSelect}
                                        >
                                            {[10, 20, 30, 40, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))}
                                        </select>

                                        <div className="d-md-none mt-3">
                                            <SearchFilter
                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                globalFilter={state.globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            />
                                        </div>
                                    </Col>
                                    <div className="d-flex col-md-8 justify-content-md-end justify-content-between align-items-start ">
                                        <div className="d-md-block d-none">
                                            <SearchFilter
                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                globalFilter={state.globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            />
                                        </div>

                                        <select
                                            className="form-select d-md-none w-50"
                                            value={pageSize}
                                            onChange={onChangeInSelect}
                                        >
                                            {[10, 20, 30, 40, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))}
                                        </select>

                                        <button onClick={(e) => {
                                            setHospitalNo("")
                                            setPatientName("")
                                            setNhsNumber("")
                                            setEmail("")
                                            setPhone("")
                                            setBirthDate("")
                                            setLocation("")
                                            setPassword("")
                                            setAddModal(true)
                                        }} className="btn btn-primary bg-dark-blue bg-blue ms-4">
                                            <i className="fas fa-plus me-3"></i>
                                            Add Patient
                                        </button>

                                        <button onClick={() => navigate(-1)} className="btn btn-dark bg-dark-blue ms-3">
                                            <i className="fas fa-arrow-left me-3"></i>
                                            Back
                                        </button>
                                    </div>
                                </Row>

                                <div className="table-responsive table-header-height">
                                    <Table
                                        bordered
                                        {...getTableProps()}
                                        className="w-800 responsive-table"
                                        style={{ verticalAlign: 'middle' }}
                                    >
                                        <thead className="table-header-fixed table-header">
                                            {headerGroups.map(headerGroup => (
                                                <tr
                                                    key={headerGroup.id}
                                                    {...headerGroup.getHeaderGroupProps()}
                                                >
                                                    {headerGroup.headers.map(column => (
                                                        <th key={column.id} className={column.className}>
                                                            <div {...column.getSortByToggleProps()}>
                                                                {column.render("Header")}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                            {/* <Filter column={column} /> */}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>

                                        <tbody {...getTableBodyProps()}>
                                            {page.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <Fragment key={row.getRowProps().key}>
                                                        <tr className="row-hover">
                                                            {row.cells.map(cell => {
                                                                return (
                                                                    <td key={cell.id} data-label={cell.column.Header}
                                                                        className={cell.column.className}
                                                                        {...cell.getCellProps()}>
                                                                        {cell.render("Cell")}
                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>

                                                    </Fragment>
                                                )
                                            })}

                                            {
                                                page.length === 0 && <tr>
                                                    <td colSpan={8} className="text-center">No Patient Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>

                                <Row className="justify-content-md-end justify-content-center align-items-center mt-2">
                                    <Col className="col-md-auto">
                                        <div className="d-flex gap-1">
                                            <Button
                                                className="bg-blue"
                                                color="primary"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<<"}
                                            </Button>
                                            <Button
                                                className="bg-blue"
                                                onClick={previousPage}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<"}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col className="col-md-auto d-none d-md-block">
                                        Page{" "}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>
                                    </Col>

                                    <Col className="col-md-auto">
                                        <div className="d-flex gap-1">
                                            <Button
                                                className="bg-blue"
                                                onClick={nextPage}
                                                disabled={!canNextPage}
                                            >
                                                {">"}
                                            </Button>
                                            <Button
                                                className="bg-blue"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                {">>"}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>

            {/*  */}
            {/* Add Patient */}
            <Modal size="md" isOpen={addModal} centered={true}>

                <div className="modal-content">
                    <div className="modal-header py-3 px-4 border-bottom bg-light-purple">
                        <h5 className="modal-title mt-0 text-purple">Add New Patient</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setAddModal(false)
                                setErrors({})
                            }}
                            className="btn-modal-close"
                        >
                            <div aria-hidden="true" className="btn-modal-span text-blue px-2 py-3 rounded-circle bg-primary bg-soft"> &times; </div>
                        </button>
                    </div>
                    <ModalBody className="px-4 pb-4">
                        <form onSubmit={handleRegisterPatient} autoComplete="off">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Hospital Number</label>
                                    <input type="text" className="form-control"
                                        value={hospitalNo}
                                        onChange={(e) => setHospitalNo(e.target.value)}
                                        placeholder="Enter Hospital Number" />
                                    {errors.hospitalNo && <span className="text-danger">{errors.hospitalNo}</span>}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Patient Name</label>
                                    <input type="text" className="form-control"
                                        value={patientName}
                                        onChange={(e) => setPatientName(e.target.value)}
                                        placeholder="Enter Patient Name" />
                                    {errors.patientName && <span className="text-danger">{errors.patientName}</span>}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">NHS Number</label>
                                    <input type="text" className="form-control"
                                        value={nhsNumber}
                                        onChange={(e) => setNhsNumber(e.target.value)}
                                        placeholder="Enter NHS Number" />
                                    {errors.nhsNumber && <span className="text-danger">{errors.nhsNumber}</span>}
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Phone Number</label>
                                    <input type="text" className="form-control"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Enter Phone Number" />
                                    {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Email Address</label>
                                    <input type="text" className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter Email Address" />
                                    {errors.email && <span className="text-danger">{errors.email}</span>}
                                </div>

                                <div className="col-md-6 mb-3 position-relative">
                                    <label htmlFor="">Password</label>
                                    <input type={passwordType} className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter Password" />
                                    {errors.password && <span className="text-danger">{errors.password}</span>}
                                    <span
                                        className="bd-pass-icon pe-4"
                                        onClick={togglePassword}
                                    >
                                        {passwordType === "password" ? (
                                            <i className="fas fa-eye "></i>
                                        ) : (
                                            <i className="fas fa-eye-slash "></i>
                                        )}
                                    </span>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Birth Date</label>
                                    <input type="date" className="form-control"
                                        value={birthDate}
                                        onChange={(e) => setBirthDate(e.target.value)}
                                    />
                                    {errors.birthDate && <span className="text-danger">{errors.birthDate}</span>}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Location</label>
                                    <input type="text" className="form-control"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        placeholder="Enter Location" />
                                    {errors.location && <span className="text-danger">{errors.location}</span>}
                                </div>

                                <div className="mt-5 text-end">
                                    <button type="submit" className="btn btn-primary bg-blue">
                                        <i className="fas fa-save me-2"></i>
                                        Submit Now
                                    </button>
                                    <button type="button" onClick={(e) => setAddModal(false)} className="btn btn-secondary bg-dark ms-3">
                                        <i className="fas fa-times me-2"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </div>
            </Modal >

            {/* Edit Surgeons */}
            < Modal size="md" isOpen={editModal} centered={true} >

                <div className="modal-content">
                    <div className="modal-header py-3 px-4 border-bottom bg-light-purple">
                        <h5 className="modal-title mt-0 text-purple">Edit Patient Details</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setEditModal(false)
                                setErrors({})
                            }}
                            className="btn-modal-close"
                        >
                            <div aria-hidden="true" className="btn-modal-span text-blue px-2 py-3 rounded-circle bg-primary bg-soft"> &times; </div>
                        </button>
                    </div>
                    <ModalBody className="px-4 pb-4">
                        <form onSubmit={handleEditPatient} autoComplete="off">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Hospital Number</label>
                                    <input type="text" className="form-control"
                                        value={hospitalNo}
                                        onChange={(e) => setHospitalNo(e.target.value)}
                                        placeholder="Enter Hospital Number" />
                                    {errors.hospitalNo && <span className="text-danger">{errors.hospitalNo}</span>}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Patient Name</label>
                                    <input type="text" className="form-control"
                                        value={patientName}
                                        onChange={(e) => setPatientName(e.target.value)}
                                        placeholder="Enter Patient Name" />
                                    {errors.patientName && <span className="text-danger">{errors.patientName}</span>}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">NHS Number</label>
                                    <input type="text" className="form-control"
                                        value={nhsNumber}
                                        onChange={(e) => setNhsNumber(e.target.value)}
                                        placeholder="Enter NHS Number" />
                                    {errors.nhsNumber && <span className="text-danger">{errors.nhsNumber}</span>}
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Phone Number</label>
                                    <input type="text" className="form-control"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Enter Phone Number" />
                                    {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Email Address</label>
                                    <input type="text" className="form-control"
                                        value={email}
                                        // onChange={(e) => setEmail(e.target.value)}
                                        readOnly
                                        placeholder="Enter Email Address" />
                                    {errors.email && <span className="text-danger">{errors.email}</span>}
                                </div>

                                {/* <div className="col-md-6 mb-3">
                                    <label htmlFor="">Password</label>
                                    <input type="password" className="form-control"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter Password" />
                                    {errors.password && <span className="text-danger">{errors.password}</span>}
                                </div> */}

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Birth Date</label>
                                    <input type="date" className="form-control"
                                        value={birthDate}
                                        onChange={(e) => setBirthDate(e.target.value)}
                                    />
                                    {errors.birthDate && <span className="text-danger">{errors.birthDate}</span>}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">Location</label>
                                    <input type="text" className="form-control"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        placeholder="Enter Location" />
                                    {errors.location && <span className="text-danger">{errors.location}</span>}
                                </div>

                                <div className="mt-5 text-end">
                                    <button type="submit" className="btn btn-primary bg-blue">
                                        <i className="fas fa-save me-2"></i>
                                        Submit Now
                                    </button>
                                    <button type="button" onClick={(e) => setEditModal(false)} className="btn btn-secondary bg-dark ms-3">
                                        <i className="fas fa-times me-2"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </div>
            </Modal >
        </div >
    )
}

export default AllPatients
