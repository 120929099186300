import CryptoJS from 'crypto-js';

export const Configure = {
    // apiUrl: "https://ztpl.net/jrh/api",
    apiUrl: "https://facefacts.uk/admin/api",
    appUrl: "/"
    // appUrl: "/jrh/admin/"
}

export const SECRET_KEY = "KL499HRCHONb3J95bb221R04247f30HA8a06="

export const getEncryptedData = (normalText) => {
    if (normalText) {
        try {
            const data = CryptoJS.AES.encrypt(
                JSON.stringify(normalText),
                SECRET_KEY
            ).toString();
            return (data);
        } catch (error) {
            // console.log("enc Invalid Key")
        }
    }
};

export const getDecryptedData = (encryptedText) => {
    if (encryptedText) {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY);
            const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return (data);

        } catch (error) {
            // console.log("dec Invalid Key")
            return encryptedText
        }
    } else {
        return encryptedText
    }
};