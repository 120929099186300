import React, { useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/jrh_user.png"
import profileImg from "../../assets/images/profile-img.png"
import secureLocalStorage from "react-secure-storage"
import AdminProfile from "./AdminProfile"

const WelcomeComp = () => {

  const [profileModal, setProfileModal] = useState(false);

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="login-bg h-auto py-2">
          <Row>
            <Col xs="7">
              <div className="text-white p-3">
                <h5 className="fw-bold">Welcome Back !</h5>
                <p className="fw-bold">{secureLocalStorage.getItem('admin')}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              {/* <img src={profileImg} alt="" className="img-fluid" /> */}
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="3">
              <div className="avatar-md  mt-2 pt-1">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </Col>

            <Col sm="9">
              <div className="pt-4 d-flex justify-content-between align-items-end">
                <div>
                  <h5 className="font-size-15 text-truncate">{secureLocalStorage.getItem("admin")}</h5>
                  <p className="text-muted mb-0 text-truncate">Head Manager</p>
                </div>
                <div>
                  <button
                    onClick={() => setProfileModal(true)}
                    className="btn btn-primary bg-blue btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <AdminProfile modal={profileModal} setModal={setProfileModal} />

    </React.Fragment>
  )
}
export default WelcomeComp
