import React, { useMemo, Fragment, useState, useEffect } from "react"
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Container, CardBody, Card, Modal, ModalBody, ModalHeader } from "reactstrap"
//redux
import SearchFilter from "components/Common/SearchFilter"
import Breadcrumb from "components/Common/Breadcrumb"
import { Link, useLocation, useNavigate } from "react-router-dom"

import swal from 'sweetalert';
import axios from "axios"
import Loader from "pages/Loader"
import { Configure, getDecryptedData } from "pages/configure"
import secureLocalStorage from "react-secure-storage"
import moment from "moment"

const PatientCases = () => {

    const [isLoading, setLoading] = useState(false)
    const [patientCases, setPatientCases] = useState([]);

    const location = useLocation()

    const navigate = useNavigate()

    useEffect(() => {
        async function getAllCases() {
            try {
                setLoading(true)

                const formData = new FormData()
                formData.append("nhs_number", location?.state?.data?.nhs_number)

                const response = await axios.post(`${Configure.apiUrl}/patients/cases`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })
                // console.log(response.data.data)
                const finalData = response.data.data.map((temp) => {
                    return ({
                        ...temp,
                        name: getDecryptedData(temp.patient_name),
                        nhs_number: temp.nhs_number,
                        hospital_number: getDecryptedData(temp.hospital_number),
                        status: ((temp.mdt_decision && temp.iotn && temp.ioftn) ? 1 : 0)
                    })
                })

                // console.log("pa ; ", finalData)

                setPatientCases(finalData)

                setLoading(false)
            } catch (error) {
                console.log("Error Fetching data: ", error)
            }
        }

        getAllCases()
    }, [])

    document.title = "Cases | John Radcliffe Hospital"


    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
                disableFilters: true,
                className: "col-1 text-center",
                Cell: cellProps => {
                    return (
                        <span>{cellProps.row.index + 1}</span>
                    )
                },
            },
            {
                Header: "Date",
                accessor: "created_at",
                disableFilters: true,
                filterable: true,
                className: "text-center",
                Cell: cellProps => <span>{moment(cellProps.row.original.created_at).format("DD-MM-YYYY")}</span>,
            },
            {
                Header: "Patient Name",
                accessor: "name",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "NHS Number",
                accessor: "nhs_number",
                disableFilters: true,
                filterable: true,
                className: "col-2"
            },
            {
                Header: "Hospital Number",
                accessor: "hospital_number",
                disableFilters: true,
                filterable: true,
                className: "col-2"
            },
            {
                Header: "Status",
                accessor: "status",
                disableFilters: true,
                filterable: true,
                className: "text-center",
                Cell: cellProps => {
                    return (
                        <div className="text-center">
                            {cellProps.row.original.status == 1 ?
                                <span className="badge bg-success bg-soft text-success "> Completed </span>
                                :
                                <span className="badge bg-danger bg-soft text-danger "> Pending </span>
                            }
                        </div>
                    )
                },
            },
            {
                Header: "Action",
                disableFilters: true,
                accessor: "view",
                className: "col-2 text-center",
                Cell: cellProps => {
                    return (
                        <div>
                            <button
                                onClick={() => {
                                    navigate(`${Configure.appUrl}verify-case`, {
                                        state: {
                                            answers: cellProps.row.original,
                                        }
                                    })
                                }}
                                className="btn btn-outline-secondary btn-sm"
                            >
                                <i className="fas fa-eye me-1"></i> Verify
                            </button>
                            {/* <button
                                onClick={() => onDelete()}
                                className="btn btn-danger btn-sm ml-10"
                            >
                                <i className="fas fa-trash-alt"></i>
                            </button> */}
                        </div>
                    )
                },
            },
        ],
        []
    )

    const data = useMemo(() => patientCases, [patientCases])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    )

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    return (
        <div>
            {
                isLoading && <Loader />
            }
            <div className="page-content">
                <Container fluid >
                    <Breadcrumb
                        title={"Dashboard"}
                        breadcrumbItem={"Patient Cases"}
                    />

                    <Row>
                        <Card className="px-0 mx-0">
                            <CardBody >

                                <Row className="mb-3 justify-space-between flex-md-row flex-column-reverse">
                                    <Col md={2}>
                                        <select
                                            className="form-select d-md-block d-none"
                                            value={pageSize}
                                            onChange={onChangeInSelect}
                                        >
                                            {[10, 20, 30, 40, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))}
                                        </select>

                                        <div className="d-md-none mt-3">
                                            <SearchFilter
                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                globalFilter={state.globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            />
                                        </div>
                                    </Col>
                                    <div className="d-flex col-md-8 justify-content-md-end justify-content-between align-items-start ">
                                        <div className="d-md-block d-none">
                                            <SearchFilter
                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                globalFilter={state.globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            />
                                        </div>

                                        <select
                                            className="form-select d-md-none w-50"
                                            value={pageSize}
                                            onChange={onChangeInSelect}
                                        >
                                            {[10, 20, 30, 40, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))}
                                        </select>

                                        <button onClick={() => navigate(`${Configure.appUrl}add-case`, {
                                            state: {
                                                user_id: location?.state?.data?.user_id
                                            }
                                        })} className="btn btn-primary bg-blue ms-4">
                                            <i className="fas fa-plus me-2"></i>
                                            Add New Case
                                        </button>

                                        <button onClick={() => navigate(-1)} className="btn btn-secondary bg-dark ms-3">
                                            <i className="fas fa-arrow-left me-3"></i>
                                            Back
                                        </button>
                                    </div>
                                </Row>

                                <div className="table-responsive">
                                    <Table
                                        bordered
                                        {...getTableProps()}
                                        className="w-800 responsive-table"
                                        style={{ verticalAlign: 'middle' }}
                                    >
                                        <thead className=" table-header">
                                            {headerGroups.map(headerGroup => (
                                                <tr
                                                    key={headerGroup.id}
                                                    {...headerGroup.getHeaderGroupProps()}
                                                >
                                                    {headerGroup.headers.map(column => (
                                                        <th key={column.id} className={column.className}>
                                                            <div {...column.getSortByToggleProps()}>
                                                                {column.render("Header")}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                            {/* <Filter column={column} /> */}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>

                                        <tbody {...getTableBodyProps()}>
                                            {page.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <Fragment key={row.getRowProps().key}>
                                                        <tr className="row-hover">
                                                            {row.cells.map(cell => {
                                                                return (
                                                                    <td key={cell.id} className={cell.column.className} data-label={cell.column.Header} {...cell.getCellProps()}>
                                                                        {cell.render("Cell")}
                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>

                                                    </Fragment>
                                                )
                                            })}

                                            {
                                                page.length === 0 && <tr>
                                                    <td colSpan={7} className="text-center">No Case Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>

                                <Row className="justify-content-md-end justify-content-center align-items-center mt-2">
                                    <Col className="col-md-auto">
                                        <div className="d-flex gap-1">
                                            <Button
                                                className="bg-blue"
                                                color="primary"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<<"}
                                            </Button>
                                            <Button
                                                className="bg-blue"
                                                onClick={previousPage}
                                                disabled={!canPreviousPage}
                                            >
                                                {"<"}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col className="col-md-auto d-none d-md-block">
                                        Page{" "}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>
                                    </Col>

                                    <Col className="col-md-auto">
                                        <div className="d-flex gap-1">
                                            <Button
                                                className="bg-blue"
                                                onClick={nextPage}
                                                disabled={!canNextPage}
                                            >
                                                {">"}
                                            </Button>
                                            <Button
                                                className="bg-blue"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                {">>"}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PatientCases
