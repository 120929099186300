import PropTypes, { func } from "prop-types";
import React, { useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, Input, Label, Form, Modal, ModalBody } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// import images
import profile from "../../assets/images/profile-img.png";
import { Configure } from "pages/configure";
import axios from "axios";
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";

const ForgetPasswordPage = props => {
  const [email, setEmail] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  //meta title
  document.title = "Forget Password | John Radcliffe Hospital";

  const navigate = useNavigate()

  const handleSendOtp = async () => {
    try {
      setLoading(true)

      const formData = {
        email
      }

      const response = await axios.post(`${Configure.apiUrl}/send_otp`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      // console.log("pwd data: ", response.data)
      setLoading(false)
      // Alert.alert("Login failed.")

      if (response.data.Message == "Email id does not exist...") {
        swal("", "This Email Id doesn't exist.", "error")
        return;
      }

      if (response.data.status == 1) {
        // console.log(response.data.data)
        secureLocalStorage.setItem('temp_data', response.data.data)
        setModal(true)
      }

    } catch (error) {
      swal("", "Something Went Wrong!", "error")
      console.log('Error: ', error);
      setLoading(false)

      // console.log(error)
    }

  }

  const handleVerifyOtp = (e) => {
    e.preventDefault()

    const optResponse = secureLocalStorage.getItem('temp_data')

    if (optResponse?.otp == otpCode) {
      setModal(false)
      swal("", "Email Verified Successfully! Now you can change your password", "success")
        .then(() => {
          navigate(`${Configure.appUrl}reset-password`)
        })

    } else {
      swal("", "Entered Otp was not matched!", "error")
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages login-page  login-bg pt-sm-5">
        <Container>
          <Row className="justify-content-center align-items-center mt-5">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={8}>
                      <div className="text-blue p-4">
                        <h5 className="text-blue fw-bold">Forgot Password</h5>
                        <p style={{ fontWeight: 500 }}>Verify Your Email Address</p>
                      </div>
                    </Col>
                    <Col className="col-4 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">

                  <div className="p-2 mt-4">
                    {/* <Alert color="danger" style={{ marginTop: "13px" }}>
                      {"Invalid Email"}
                    </Alert> */}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSendOtp()
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <button
                        className="btn btn-primary bg-blue w-100 "
                        type="submit"
                      >
                        {
                          loading &&
                          <i className="bx bx-hourglass bx-spin align-middle me-2"></i>
                        }
                        {loading ? "Verifying & sending OTP.." : "Submit"}
                      </button>

                    </Form>
                  </div>
                  <div className="mt-4 text-center">
                    <p>
                      Go back to{" "}
                      <Link to={`${Configure.appUrl}login`} className="font-weight-medium text-primary">
                        Login
                      </Link>{" "}
                    </p>
                    <p>
                    © {new Date().getFullYear()} FaceFacts. Crafted with{" "}
                            <i className="mdi mdi-heart text-danger" /> by Zithas Technologies
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>


      <Modal size="md" isOpen={modal} centered={true}>

        <div className="modal-content">
          <div className="modal-header py-3 px-4 border-bottom bg-light-purple">
            <div>
              <h5 className=" mt-0 text-purple ">
                Otp Sent Successfully!
              </h5>
              <p>
                We have sent verification code on <span className="text-blue fw-bold">{email}</span>. <br /> please check and verify it.
              </p>
            </div>
          </div>
          <ModalBody className="px-4 pb-4">
            <form onSubmit={handleVerifyOtp} autoComplete="off">
              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="">Enter Verification Code</label>
                  <input type="text" className="form-control"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                    placeholder="000000" maxLength={6} required />

                </div>


                <div className="mt-4 text-center">
                  <button type="submit" className="btn btn-primary bg-blue">
                    Verify Otp
                  </button>
                </div>
              </div>
            </form>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
