import React, { useEffect, useState } from "react"

import { Row, Container, CardBody, Card } from "reactstrap"

import Breadcrumb from "components/Common/Breadcrumb"
import { useLocation, useNavigate } from "react-router-dom"

import swal from 'sweetalert';
import axios from "axios"
import Loader from "pages/Loader"
import { Configure, getDecryptedData, getEncryptedData } from "pages/configure"
import secureLocalStorage from "react-secure-storage"
import StatusBadge from "./StatusBadge";

const AddCase = () => {

    const [isLoading, setLoading] = useState(false)

    const [radioQuestion1, setRadioQuestion1] = useState(0);
    const [radioQuestion2, setRadioQuestion2] = useState(0);
    const [radioQuestion3, setRadioQuestion3] = useState(0);
    const [radioQuestion4, setRadioQuestion4] = useState(0);
    const [radioQuestion5, setRadioQuestion5] = useState(0);
    const [radioQuestion6, setRadioQuestion6] = useState(0);
    const [radioQuestion7, setRadioQuestion7] = useState(0);
    const [radioQuestion8, setRadioQuestion8] = useState(0);

    const [step3_quest1, setStep3Ques1] = useState(0);
    const [step3_quest2, setStep3Ques2] = useState(0);
    const [step3_quest3, setStep3Ques3] = useState(0);
    const [step3_quest4, setStep3Ques4] = useState(0);
    const [step3_quest5, setStep3Ques5] = useState(0);
    const [step3_quest6, setStep3Ques6] = useState(0);
    const [step3_quest7, setStep3Ques7] = useState(0);
    const [step3_quest8, setStep3Ques8] = useState(0);
    const [step3_quest9, setStep3Ques9] = useState(0);
    const [step3_quest10, setStep3Ques10] = useState(0);

    const [step4_quest1, setStep4Ques1] = useState(0);
    const [step4_quest2, setStep4Ques2] = useState(0);
    const [step4_quest3, setStep4Ques3] = useState(0);
    const [step4_quest4, setStep4Ques4] = useState(0);
    // const [step4_quest5, setStep4Ques5] = useState(0);

    const [step5_quest1, setStep5Ques1] = useState(0);
    const [step5_quest2, setStep5Ques2] = useState(0);

    const [yourBite, setYourBite] = useState("");
    const [yourAppearance, setYourAppearance] = useState("");
    const [education_impact, setEducationImpact] = useState("");

    const [caseId, setCaseId] = useState('');
    const [userId, setUserId] = useState('');

    const [hospitalNo, setHospitalNo] = useState('');
    const [patientName, setPatientName] = useState('');
    const [nhsNumber, setNhsNumber] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [location, setLocation] = useState('');
    // const [password, setPassword] = useState('');

    // state for errors messages
    const [errors, setErrors] = useState({
        hospitalNo: '',
        patientName: '',
        nhsNumber: '',
        email: '',
        phone: "",
        birthDate: "",
        location: ""
    });

    const navigate = useNavigate()

    const locationData = useLocation()
    // getting existing user details
    useEffect(() => {
        if (locationData.state?.user_id) {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", locationData.state?.user_id)
            axios.post(`${Configure.apiUrl}/profile`, formData, {
                headers: {
                    Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                setHospitalNo(getDecryptedData(response.data.data[0].hospital_number))
                setPatientName(getDecryptedData(response.data.data[0].name))
                setNhsNumber(response.data.data[0].nhs_number)
                setEmail(response.data.data[0].email)
                setPhone(getDecryptedData(response.data.data[0].phone))
                setBirthDate(getDecryptedData(response.data.data[0].birth_date))
                setLocation(getDecryptedData(response.data.data[0].location))
                setUserId(locationData.state?.user_id)

                setLoading(false)
                // console.log(response.data)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])

    document.title = "Add New Case | John Radcliffe Hospital"

    const [step, setStep] = useState(0);


    const onBackTab = (index) => {
        if (step > 0) {
            setStep(step - 1)
            window.scrollTo(0, 0)
        }
    }

    const onNextTab = (index) => {
        if (step < 5) {
            setStep(step + 1)
            window.scrollTo(0, 0)
        }
    }

    // validations for first form
    const handleValidation = () => {

        const newErrors = {};

        if (!hospitalNo) {
            newErrors.hospitalNo = 'Hospital no. is required';
        }

        if (!patientName) {
            newErrors.patientName = 'Patient name is required';
        }

        if (!nhsNumber) {
            newErrors.nhsNumber = 'NHS number is required';
        } else if (!(/^[0-9]*$/).test(nhsNumber)) {
            newErrors.nhsNumber = "Only 0-9 digits allowed";
        } else if (nhsNumber.length > 10 || nhsNumber.length < 10) {
            newErrors.nhsNumber = 'NHS number must be of 10 digits';
        }

        if (!birthDate) {
            newErrors.birthDate = 'Birth date is required';
        }

        if (!location) {
            newErrors.location = 'Location is required';
        } else if ((/\d/).test(location)) {
            newErrors.location = 'Location can only contain letters';
        }

        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            newErrors.email = 'Invalid email format';
        }

        if (!phone) {
            newErrors.phone = 'Phone number is required';
        } else if (phone.length < 7 || phone.length > 15) {
            newErrors.phone = 'Invalid Phone Number Length';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    // ===========================

    // step 1 api calling
    const handleSaveForm1 = async () => {
        try {
            if (handleValidation()) {
                setLoading(true)
                if (caseId == "" && userId == "") {
                    const formData = new FormData()
                    formData.append("hospital_number", getEncryptedData(hospitalNo));
                    formData.append("name", getEncryptedData(patientName));
                    formData.append("nhs_number", nhsNumber);
                    formData.append("email", email);
                    formData.append("phone", getEncryptedData(phone));
                    formData.append("birth_date", getEncryptedData(birthDate));
                    formData.append("location", getEncryptedData(location));

                    const response = await axios.post(`${Configure.apiUrl}/case/signup`, formData, {
                        headers: {
                            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                            'Content-Type': 'application/json'
                        }
                    })

                    if (response.data.success == false && response.data.message == "NHS already exists") {
                        swal("", response.data.message, "warning");
                        setLoading(false)
                        return;
                    }

                    if (response.data.data == "Email id is already exists...") {
                        swal("", "This Email Already Exist!", "warning")
                        setLoading(false)
                        return;
                    }

                    // console.log(response.data)

                    if (response.data.success == true) {
                        setCaseId(response.data.data.case_id)
                        setUserId(response.data.data.user_id)
                        setLoading(false)

                        swal("Personal Details Saved!", "Press Ok to Move on Next Step.", "success")
                            .then(() => {
                                onNextTab()
                            })
                    }

                } else {
                    onNextTab()
                }
            }

        } catch (error) {
            console.log('Error fetching data: ', error);
            setLoading(false)
            swal("", 'Something went wrong!', "error")
        }
    }

    // step 1 update profile data

    const handleEditPatient = async () => {

        try {
            if (handleValidation(false)) {
                setLoading(true)
                const formData = new FormData()

                formData.append("hospital_number", getEncryptedData(hospitalNo));
                formData.append("name", getEncryptedData(patientName));
                formData.append("nhs_number", nhsNumber);
                formData.append("phone", getEncryptedData(phone));
                formData.append("birth_date", getEncryptedData(birthDate));
                formData.append("location", getEncryptedData(location));
                formData.append("email", email);
                formData.append("user_id", locationData.state?.user_id);

                const response = await axios.post(`${Configure.apiUrl}/case/update`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                if (response.data.success == true) {
                    setCaseId(response.data.data.case_id)
                    setUserId(response.data.data.user_id)

                    setLoading(false)
                    swal("Personal Details Saved!", "Press Ok to Move on Next Step.", "success")
                        .then(() => {
                            onNextTab()
                        })
                }
            }

        } catch (error) {
            console.log('Error fetching data: ', error);
            swal("", "Something Went Wrong", "error")
            setLoading(false)
        }
    }

    // ==============================
    const validateForm2 = () => {
        let result = true;
        if (radioQuestion1 == 0)
            result = false;

        if (radioQuestion2 == 0)
            result = false;

        if (radioQuestion3 == 0)
            result = false;

        if (radioQuestion4 == 0)
            result = false;

        if (!result)
            swal("", "All Fields Required!", "warning");

        return result;
    }

    // step 2 api calling
    const handleSaveForm2 = async (e) => {
        e.preventDefault()
        if (validateForm2()) {
            try {
                setLoading(true)

                const formData = new FormData()

                // step 2
                formData.append("feeling", getEncryptedData(radioQuestion1));
                formData.append("worrying", getEncryptedData(radioQuestion2));
                formData.append("condition", getEncryptedData(radioQuestion3));
                formData.append("pleasure", getEncryptedData(radioQuestion4));

                let apiName;
                // decision
                // if (userData.type == 1) {
                formData.append("case_id", caseId)
                formData.append("user_id", userId)
                apiName = "case/wellbeing"
                // } else {
                //     formData.user_id = userData.patientId
                //     formData.nhs_number = userData.patientId
                //     formData.hospital_number = userData.patientId
                //     apiName = "patient/new_case"
                // }

                const response = await axios.post(`${Configure.apiUrl}/${apiName}`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                // console.log("wellbeing ", response.data)

                if (response.data.success == true) {
                    setCaseId(response.data.data.case_id)
                    setUserId(response.data.data.user_id)
                    setLoading(false)

                    swal("Data Saved Successfully!", "Press Ok to Move on Next Step.", "success")
                        .then(() => {
                            onNextTab()
                        })

                }

            } catch (error) {
                console.log('Error fetching data: ', error);
                swal("", 'Something went wrong!', "error")
                setLoading(false)
            }
        }
    }

    const validateForm3 = () => {
        let result = true;
        if (radioQuestion5 == 0)
            result = false;

        if (radioQuestion6 == 0)
            result = false;

        if (radioQuestion7 == 0)
            result = false;

        if (radioQuestion8 == 0)
            result = false;

        if (!result)
            swal("", "All Fields Required!", "warning");


        return result;
    }

    // step 3 api calling
    const handleSaveForm3 = async (e) => {
        e.preventDefault()
        if (validateForm3()) {
            try {
                setLoading(true)

                const formData = new FormData()

                formData.append("appearance", getEncryptedData(radioQuestion5));
                formData.append("behaviours", getEncryptedData(radioQuestion6));
                formData.append("activities", getEncryptedData(radioQuestion7));
                formData.append("weight", getEncryptedData(radioQuestion8));
                formData.append("case_id", caseId);
                formData.append("user_id", userId);

                const response = await axios.post(`${Configure.apiUrl}/case/appearance`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                // console.log("appearance ", response.data)

                if (response.data.success == true) {
                    setCaseId(response.data.data.case_id)
                    setUserId(response.data.data.user_id)
                    setLoading(false)

                    swal("Data Saved Successfully!", "Press Ok to Move on Next Step.", "success")
                        .then(() => {
                            onNextTab()
                        })
                }

            } catch (error) {
                console.log('Error fetching data: ', error);
                setLoading(false)
                swal("", 'Something went wrong!', "error")
            }
        }
    }

    const validateForm4 = () => {
        let result = true;
        if (step3_quest1 == 0)
            result = false;

        if (step3_quest2 == 0)
            result = false;

        if (step3_quest3 == 0)
            result = false;

        if (step3_quest4 == 0)
            result = false;

        if (step3_quest5 == 0)
            result = false;

        if (step3_quest6 == 0)
            result = false;

        if (step3_quest7 == 0)
            result = false;

        if (step3_quest8 == 0)
            result = false;

        if (step3_quest9 == 0)
            result = false;

        if (step3_quest10 == 0)
            result = false;

        if (!result)
            swal("", "All Fields Required!", "warning");


        return result;
    }

    // step 4 api calling
    const handleSaveForm4 = async (e) => {
        e.preventDefault()
        if (validateForm4()) {
            try {
                setLoading(true)

                const formData = new FormData()

                formData.append("teeth_position", getEncryptedData(step3_quest1));
                formData.append("teeth_bite", getEncryptedData(step3_quest2));
                formData.append("face", getEncryptedData(step3_quest3));
                formData.append("cheeks", getEncryptedData(step3_quest4));
                formData.append("lower_jaw", getEncryptedData(step3_quest5));
                formData.append("chin", getEncryptedData(step3_quest6));
                formData.append("cheeck_bone", getEncryptedData(step3_quest7));
                formData.append("jaw_joint", getEncryptedData(step3_quest8));
                formData.append("night_snore", getEncryptedData(step3_quest9));
                formData.append("sleepy_feel", getEncryptedData(step3_quest10));
                formData.append("case_id", caseId);
                formData.append("user_id", userId);

                const response = await axios.post(`${Configure.apiUrl}/case/function`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                // console.log("function ", response.data)

                if (response.data.success == true) {
                    setCaseId(response.data.data.case_id)
                    setUserId(response.data.data.user_id)
                    setLoading(false)

                    swal("Data Saved Successfully!", "Press Ok to Move on Next Step.", "success")
                        .then(() => {
                            onNextTab()
                        })

                }

            } catch (error) {
                console.log('Error fetching data: ', error);
                setLoading(false)
                swal("", 'Something went wrong!', "error")
            }
        }
    }

    const validateForm5 = () => {
        let result = true;
        if (step4_quest1 == 0)
            result = false;

        if (step4_quest2 == 0)
            result = false;

        if (step4_quest3 == 0)
            result = false;

        if (step4_quest4 == 0)
            result = false;

        if (!result)
            swal("", "All Fields Required!", "warning");


        return result;
    }

    // step 4 api calling
    const handleSaveForm5 = async (e) => {
        e.preventDefault()
        if (validateForm5()) {
            try {
                setLoading(true)

                const formData = new FormData()

                formData.append("ortho_treatment", getEncryptedData(step4_quest1));
                formData.append("numbness", getEncryptedData(step4_quest2));
                formData.append("nasal", getEncryptedData(step4_quest3));
                formData.append("revision_operation", getEncryptedData(step4_quest4));
                formData.append("case_id", caseId);
                formData.append("user_id", userId);

                const response = await axios.post(`${Configure.apiUrl}/case/complications`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                // console.log("complications ", response.data)

                if (response.data.success == true) {
                    setCaseId(response.data.data.case_id)
                    setUserId(response.data.data.user_id)
                    setLoading(false)

                    swal("Data Saved Successfully!", "Press Ok to Move on Next Step.", "success")
                        .then(() => {
                            onNextTab()
                        })

                }

            } catch (error) {
                console.log('Error fetching data: ', error);
                setLoading(false)
                swal("", 'Something went wrong!', "error")
            }
        }
    }

    const validateForm6 = () => {
        let result = true;
        if (step5_quest1 == 0)
            result = false;

        if (step5_quest2 == 0)
            result = false;

        if (!yourBite.length)
            result = false;

        if (!yourAppearance.length)
            result = false;

        if (!education_impact.length)
            result = false;

        if (!result)
            swal("", "All Fields Required!", "warning");


        return result;
    }

    // step 4 api calling
    const handleSaveForm6 = async (e) => {
        e.preventDefault()
        if (validateForm6()) {
            try {
                setLoading(true)

                const formData = new FormData()

                formData.append("treatment", getEncryptedData(step5_quest1));
                formData.append("support", getEncryptedData(step5_quest2));
                formData.append("bite_change", getEncryptedData(yourBite));
                formData.append("appearance_change", getEncryptedData(yourAppearance));
                formData.append("education_impact", getEncryptedData(education_impact));
                formData.append("case_id", caseId);
                formData.append("user_id", userId);

                const response = await axios.post(`${Configure.apiUrl}/case/circumstances`, formData, {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                        'Content-Type': 'application/json'
                    }
                })

                // console.log("circumstances ", response.data)

                if (response.data.success == true) {
                    setCaseId(response.data.data.case_id)
                    setUserId(response.data.data.user_id)
                    setLoading(false)

                    swal("Data Saved Successfully!", "All Details Saved Successfully.", "success")
                        .then(() => {
                            navigate(`${Configure.appUrl}cases`)
                        })

                }

            } catch (error) {
                console.log('Error fetching data: ', error);
                setLoading(false)
                swal("", 'Something went wrong!', "error")
            }
        }
    }

    const [stepTitle, setStepTitle] = useState("Personal Details")

    const steps = [
        "Personal Details",
        "We are going to ask about your wellbeing?",
        "We are going to ask about your appearance?",
        "We are going to ask about Bite and Function?",
        "How much would you accept these complications which may result from treatment?",
        "We going about your personal circumstances and motivation for treatment"
    ]

    return (
        <div>
            {
                isLoading && <Loader />
            }
            <div className="page-content">
                <Container fluid >
                    <Breadcrumb
                        title={"Dashboard"}
                        breadcrumbItem={"Add New Case"}
                    />

                    <Row>
                        {/* <form action=""> */}
                        <Card className="px-0">
                            <CardBody>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="mb-0 pe-md-4">{stepTitle}</h5 >
                                    <div className="d-flex justify-content-between steps-box">
                                        {
                                            steps.map((stepName, index) => {
                                                return <div key={stepName}
                                                    className={`border rounded-circle fs-5  ${index < step && "bg-blue text-white"} ${index == step && "bg-light"}`}
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        display: "grid",
                                                        placeItems: "center",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                        if (caseId != "" && userId != "") {
                                                            setStep(index)
                                                            setStepTitle(stepName)
                                                        }
                                                    }}
                                                >{index + 1}</div>
                                            })
                                        }
                                        <div className="step-line"></div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                        {
                            step == 0 &&
                            <Card className="px-0">
                                <CardBody>
                                    <form autoComplete="off">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="">Hospital Number <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control"
                                                    value={hospitalNo}
                                                    onChange={(e) => setHospitalNo(e.target.value)}
                                                    placeholder="Enter Hospital Number" />
                                                {errors.hospitalNo && <span className="text-danger">{errors.hospitalNo}</span>}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="">Patient Name <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control"
                                                    value={patientName}
                                                    onChange={(e) => setPatientName(e.target.value)}
                                                    placeholder="Enter Patient Name" />
                                                {errors.patientName && <span className="text-danger">{errors.patientName}</span>}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="">NHS Number <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control"
                                                    value={nhsNumber}
                                                    onChange={(e) => setNhsNumber(e.target.value)}
                                                    placeholder="Enter NHS Number"
                                                    readOnly={locationData.state?.user_id ? true : false}
                                                />
                                                {errors.nhsNumber && <span className="text-danger">{errors.nhsNumber}</span>}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="">Phone Number <span className="text-danger">*</span></label>
                                                <input type="number" className="form-control"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    placeholder="Enter Phone Number" />
                                                {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="">Email Address <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control"
                                                    autoComplete="off"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter Email Address" />
                                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="">Birth Date <span className="text-danger">*</span></label>
                                                <input type="date" className="form-control"
                                                    value={birthDate}
                                                    onChange={(e) => setBirthDate(e.target.value)}
                                                />
                                                {errors.birthDate && <span className="text-danger">{errors.birthDate}</span>}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="">Location <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                    placeholder="Enter Location" />
                                                {errors.location && <span className="text-danger">{errors.location}</span>}
                                            </div>

                                        </div>
                                    </form>

                                    <div className="text-end mt-4">
                                        {/* <button onClick={onBackTab} className="btn btn-secondary bg-dark ">
                                            <i className="fas fa-arrow-left me-2"></i>
                                            Back
                                        </button> */}

                                        <button onClick={() => {
                                            if (locationData.state?.user_id) {
                                                handleEditPatient()
                                            } else {
                                                handleSaveForm1()
                                            }
                                        }} className="btn btn-primary bg-blue ms-4">
                                            <i className="fas fa-save me-2"></i>
                                            Save & Move on Next Step
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        }

                        {
                            step == 1 &&
                            <Card className="px-0 mx-0">
                                <CardBody >
                                    <form onSubmit={handleSaveForm2}>
                                        <div className="row">

                                            <div className="col-12 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">1. </span> Over the last two weeks, how often have you been feeling
                                                    nervous, anxious or on edge?
                                                </p>

                                                <div className="d-flex">
                                                    <div className="ms-4">
                                                        <input type="radio" id="radio1" name="radio1" value={1} defaultChecked={radioQuestion1 == 1} onChange={(e) => setRadioQuestion1(e.target.value)} required />
                                                        <label htmlFor="radio1" className="ms-2">Not at All</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio2" name="radio1" value={2} defaultChecked={radioQuestion1 == 2} onChange={(e) => setRadioQuestion1(e.target.value)} required />
                                                        <label htmlFor="radio2" className="ms-2">Several Days</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio3" name="radio1" value={3} defaultChecked={radioQuestion1 == 3} onChange={(e) => setRadioQuestion1(e.target.value)} required />
                                                        <label htmlFor="radio3" className="ms-2">More than half Days</label>
                                                    </div>

                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio4" name="radio1" value={4} defaultChecked={radioQuestion1 == 4} onChange={(e) => setRadioQuestion1(e.target.value)} required />
                                                        <label htmlFor="radio4" className="ms-2">Nearly Everyday</label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">2. </span> Over the last two weeks how often have you not been able to stop or control worrying?
                                                </p>

                                                <div className="d-flex">
                                                    <div className="ms-4">
                                                        <input type="radio" id="radio5" name="radio2" value={1} defaultChecked={radioQuestion2 == 1} onChange={(e) => setRadioQuestion2(e.target.value)} required />
                                                        <label htmlFor="radio5" className="ms-2">Not at All</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio6" name="radio2" value={2} defaultChecked={radioQuestion2 == 2} onChange={(e) => setRadioQuestion2(e.target.value)} required />
                                                        <label htmlFor="radio6" className="ms-2">Several Days</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio7" name="radio2" value={3} defaultChecked={radioQuestion2 == 3} onChange={(e) => setRadioQuestion2(e.target.value)} required />
                                                        <label htmlFor="radio7" className="ms-2">More than half Days</label>
                                                    </div>

                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio8" name="radio2" value={4} defaultChecked={radioQuestion2 == 4} onChange={(e) => setRadioQuestion2(e.target.value)} required />
                                                        <label htmlFor="radio8" className="ms-2">Nearly Everyday</label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">3    . </span>
                                                    Over the last two weeks how often have you been feeling down,
                                                    depressed or hopeless?
                                                </p>

                                                <div className="d-flex">
                                                    <div className="ms-4">
                                                        <input type="radio" id="radio9" name="radio3" value={1} defaultChecked={radioQuestion3 == 1} onChange={(e) => setRadioQuestion3(e.target.value)} required />
                                                        <label htmlFor="radio9" className="ms-2">Not at All</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio10" name="radio3" value={2} defaultChecked={radioQuestion3 == 2} onChange={(e) => setRadioQuestion3(e.target.value)} required />
                                                        <label htmlFor="radio10" className="ms-2">Several Days</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio11" name="radio3" value={3} defaultChecked={radioQuestion3 == 3} onChange={(e) => setRadioQuestion3(e.target.value)} required />
                                                        <label htmlFor="radio11" className="ms-2">More than half Days</label>
                                                    </div>

                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio12" name="radio3" value={4} defaultChecked={radioQuestion3 == 4} onChange={(e) => setRadioQuestion3(e.target.value)} required />
                                                        <label htmlFor="radio12" className="ms-2">Nearly Everyday</label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">4. </span>
                                                    Over the last two weeks how often have you been feeling little
                                                    interest or pleasure in doing things
                                                </p>

                                                <div className="d-flex">
                                                    <div className="ms-4">
                                                        <input type="radio" id="radio13" name="radio4" value={1} defaultChecked={radioQuestion4 == 1} onChange={(e) => setRadioQuestion4(e.target.value)} required />
                                                        <label htmlFor="radio13" className="ms-2">Not at All</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio14" name="radio4" value={2} defaultChecked={radioQuestion4 == 2} onChange={(e) => setRadioQuestion4(e.target.value)} required />
                                                        <label htmlFor="radio14" className="ms-2">Several Days</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio15" name="radio4" value={3} defaultChecked={radioQuestion4 == 3} onChange={(e) => setRadioQuestion4(e.target.value)} required />
                                                        <label htmlFor="radio15" className="ms-2">More than half Days</label>
                                                    </div>

                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="radio16" name="radio4" value={4} defaultChecked={radioQuestion4 == 4} onChange={(e) => setRadioQuestion4(e.target.value)} required />
                                                        <label htmlFor="radio16" className="ms-2">Nearly Everyday</label>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="text-end mt-4">
                                            <button onClick={onBackTab} className="btn btn-secondary bg-dark ">
                                                <i className="fas fa-arrow-left me-2"></i>
                                                Back
                                            </button>

                                            <button type="submit" className="btn btn-primary bg-blue ms-4">
                                                <i className="fas fa-save me-2"></i>
                                                Save & Move on Next Questions
                                            </button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        }

                        {
                            step == 2 &&
                            <Card className="px-0 mx-0">
                                <CardBody >
                                    <form onSubmit={handleSaveForm3}>
                                        <div className="row">

                                            {/* ================================================= */}

                                            <div className="col-12 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">5. </span>
                                                    Do you spend an hour or more everyday worrying about your
                                                    appearance?
                                                </p>

                                                <div className="d-flex">
                                                    <div className="ms-4">
                                                        <input type="radio" id="yes1" name="yesno1" value={1} defaultChecked={radioQuestion5 == 1} onChange={(e) => setRadioQuestion5(e.target.value)} required />
                                                        <label htmlFor="yes1" className="ms-2">Yes</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="no1" name="yesno1" value={2} defaultChecked={radioQuestion5 == 2} onChange={(e) => setRadioQuestion5(e.target.value)} required />
                                                        <label htmlFor="no1" className="ms-2">No</label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">6. </span>
                                                    Do you find yourself carrying out lots of behaviours (e.g.
                                                    mirror checking, grooming) and/or mental acts (e.g. comparing
                                                    yourself with others) in an effort to cope with your
                                                    appearance worries?
                                                </p>

                                                <div className="d-flex">
                                                    <div className="ms-4">
                                                        <input type="radio" id="yes2" name="yesno2" value={1} defaultChecked={radioQuestion6 == 1} onChange={(e) => setRadioQuestion6(e.target.value)} required />
                                                        <label htmlFor="yes2" className="ms-2">Yes</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="no2" name="yesno2" value={2} defaultChecked={radioQuestion6 == 2} onChange={(e) => setRadioQuestion6(e.target.value)} required />
                                                        <label htmlFor="no2" className="ms-2">No</label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">7. </span>
                                                    Do your appearance worries make you feel miserable (e.g.
                                                    anxious, depressed, ashamed) and/or get in the way of daily
                                                    activities (e.g. socialising, school, work)?
                                                </p>

                                                <div className="d-flex">
                                                    <div className="ms-4">
                                                        <input type="radio" id="yes3" name="yesno3" value={1} defaultChecked={radioQuestion7 == 1} onChange={(e) => setRadioQuestion7(e.target.value)} required />
                                                        <label htmlFor="yes3" className="ms-2">Yes</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="no3" name="yesno3" value={2} defaultChecked={radioQuestion7 == 2} onChange={(e) => setRadioQuestion7(e.target.value)} required />
                                                        <label htmlFor="no3" className="ms-2">No</label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">8. </span>
                                                    Are your appearance concerns focussed on your weight?
                                                </p>

                                                <div className="d-flex">
                                                    <div className="ms-4">
                                                        <input type="radio" id="yes4" name="yesno4" value={1} defaultChecked={radioQuestion8 == 1} onChange={(e) => setRadioQuestion8(e.target.value)} required />
                                                        <label htmlFor="yes4" className="ms-2">Yes</label>
                                                    </div>
                                                    <div className="ms-5 ps-4">
                                                        <input type="radio" id="no4" name="yesno4" value={2} defaultChecked={radioQuestion8 == 2} onChange={(e) => setRadioQuestion8(e.target.value)} required />
                                                        <label htmlFor="no4" className="ms-2">No</label>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="text-end mt-4">
                                            <button onClick={onBackTab} className="btn btn-secondary bg-dark ">
                                                <i className="fas fa-arrow-left me-2"></i>
                                                Back
                                            </button>

                                            <button type="submit" className="btn btn-primary bg-blue ms-4">
                                                <i className="fas fa-save me-2"></i>
                                                Save & Move on Next Questions
                                            </button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        }

                        {
                            step == 3 &&
                            <Card className="px-0 mx-0">
                                <CardBody >
                                    <form onSubmit={handleSaveForm4}>
                                        <div className="row">

                                            {/* ================================================== */}

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">9. </span>
                                                    Do you feel you have any problems with biting or chewing
                                                    related to position of your teeth?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest1} onChange={(e) => setStep3Ques1(e.target.value)} required />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest1}</span>
                                                    </div>
                                                    <StatusBadge text1={'1 Being No Problems'} text2={'10 Very Problematic'} />

                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">10. </span>
                                                    How do you feel about the appearance of your teeth and bite?
                                                </p>

                                                <div className="mt-md-4 pt-md-2">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest2} onChange={(e) => setStep3Ques2(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest2}</span>
                                                    </div>
                                                    <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />
                                                </div>

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">11. </span>
                                                    How do you feel about the appearance of your face?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest3} onChange={(e) => setStep3Ques3(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest3}</span>
                                                    </div>
                                                    <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />
                                                </div>

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">12. </span>
                                                    How do you feel about the appearance of your upper jaw/
                                                    cheeks?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest4} onChange={(e) => setStep3Ques4(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest4}</span>
                                                    </div>
                                                </div>
                                                <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">13. </span>
                                                    How do you feel about the appearance of your lower jaw?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest5} onChange={(e) => setStep3Ques5(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest5}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">14. </span>
                                                    How do you feel about the appearance of your chin?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest6} onChange={(e) => setStep3Ques6(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest6}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">15. </span>
                                                    How do you feel about the appearance of your cheek bones?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest7} onChange={(e) => setStep3Ques7(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest7}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 very happy'} text2={'10 very unhappy'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">16. </span>
                                                    Do you have any jaw joint problems?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest8} onChange={(e) => setStep3Ques8(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest8}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 No Problems'} text2={'10 Very Problematic'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">17. </span>
                                                    Do you snore at night?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest9} onChange={(e) => setStep3Ques9(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest9}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 Never'} text2={'10 Every Night'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">18. </span>
                                                    Do you feel sleepy during the day after a full night’s sleep?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step3_quest10} onChange={(e) => setStep3Ques10(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step3_quest10}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 Not Sleepy At All'} text2={'10 Very Sleepy'} />

                                            </div>

                                        </div>
                                        <div className="text-end mt-4">
                                            <button onClick={onBackTab} className="btn btn-secondary bg-dark ">
                                                <i className="fas fa-arrow-left me-2"></i>
                                                Back
                                            </button>

                                            <button type="submit" className="btn btn-primary bg-blue ms-4">
                                                <i className="fas fa-save me-2"></i>
                                                Save & Move on Next Questions
                                            </button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        }

                        {/* ================================================== */}

                        {
                            step == 4 &&
                            <Card className="px-0 mx-0">
                                <CardBody >
                                    <form onSubmit={handleSaveForm5}>
                                        <div className="row">

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">19. </span>
                                                    Orthodontic treatment time can be 2-3 years. If this was
                                                    prolonged how much would this concern you?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step4_quest1} onChange={(e) => setStep4Ques1(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step4_quest1}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 Not concerned'} text2={'10 Very concerned'} />
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">20. </span>
                                                    If you have permanent numbness or altered sensation of your lips, chin and / or tongue following surgery how much would this concern you?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step4_quest2} onChange={(e) => setStep4Ques2(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step4_quest2}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 Not concerned'} text2={'10 Very concerned'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">21. </span>
                                                    If your nose appearance was made worse after surgery how much
                                                    would this concern you?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step4_quest3} onChange={(e) => setStep4Ques3(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step4_quest3}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 Not concerned'} text2={'10 Very concerned'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">22. </span>
                                                    Up to 10% of patients may need a revision operation. How much
                                                    would another operation concern you?
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step4_quest4} onChange={(e) => setStep4Ques4(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step4_quest4}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 Not concerned'} text2={'10 Very concerned'} />

                                            </div>

                                        </div>
                                        <div className="text-end mt-4">
                                            <button onClick={onBackTab} className="btn btn-secondary bg-dark ">
                                                <i className="fas fa-arrow-left me-2"></i>
                                                Back
                                            </button>

                                            <button type="submit" className="btn btn-primary bg-blue ms-4">
                                                <i className="fas fa-save me-2"></i>
                                                Save & Move on Next Questions
                                            </button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        }

                        {
                            step == 5 &&
                            <Card className="px-0 mx-0">
                                <CardBody >
                                    <form onSubmit={handleSaveForm6}>
                                        <div className="row">

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">23. </span>
                                                    How much do you want the treatment
                                                </p>

                                                <div className="mt-md-4 pt-md-2">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step5_quest1} onChange={(e) => setStep5Ques1(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step5_quest1}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 Not at all'} text2={'10 Very much'} />
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">24. </span>
                                                    Treatment may involve multiple visits with possible surgery.
                                                    Are you satisfied you have adequate support at home to
                                                    complete treatment
                                                </p>

                                                <div className="">
                                                    <div className="d-flex align-items-center ms-4">
                                                        <input type="range" min={0} max={10} value={step5_quest2} onChange={(e) => setStep5Ques2(e.target.value)} />
                                                        <span className="ms-4 fs-5 p-2 rounded-circle border py-0">{step5_quest2}</span>
                                                    </div>
                                                </div>                                            <StatusBadge text1={'1 Not support'} text2={'10 Lots of support'} />

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">25. </span>
                                                    When considering jaw surgery What do you want to change about
                                                    your <br /> bite ?
                                                </p>

                                                <div className="">
                                                    <textarea rows="2" placeholder="Enter Your Answer"
                                                        value={yourBite}
                                                        onChange={(e) => setYourBite(e.target.value)}
                                                        className="form-control" required></textarea>
                                                </div>

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">26. </span>
                                                    When considering jaw surgery What do you want to change about
                                                    your appearance?
                                                </p>

                                                <div className="">
                                                    <textarea rows="2" placeholder="Enter Your Answer"
                                                        value={yourAppearance}
                                                        onChange={(e) => setYourAppearance(e.target.value)}
                                                        className="form-control" required></textarea>
                                                </div>

                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <p className="d-flex" style={{ color: "#005eb8" }}>
                                                    <span className="fw-bold me-2">27. </span>
                                                    How much impact will treatment have on education / work?
                                                </p>

                                                <div className="">
                                                    <textarea rows="2" placeholder="Enter Your Answer"
                                                        value={education_impact}
                                                        onChange={(e) => setEducationImpact(e.target.value)}
                                                        className="form-control" required></textarea>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="text-end mt-4">
                                            <button onClick={onBackTab} className="btn btn-secondary bg-dark ">
                                                <i className="fas fa-arrow-left me-2"></i>
                                                Back
                                            </button>

                                            <button type="submit" className="btn btn-primary bg-blue ms-4">
                                                <i className="fas fa-save me-2"></i>
                                                Submit Now
                                            </button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        }
                        {/* </form> */}
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AddCase
